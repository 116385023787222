import React from "react";
import "./WhatWeDo.css"; // Custom CSS for styling
import sun from "../../img/Solutions Page/WhatWeDo/sun.svg";
import zip from "../../img/Solutions Page/WhatWeDo/zip.svg";
import star from "../../img/Solutions Page/WhatWeDo/star.svg";
import shield from "../../img/Solutions Page/WhatWeDo/shield.svg";
import rocket from "../../img/Solutions Page/WhatWeDo/rocket.svg";
import phone from "../../img/Solutions Page/WhatWeDo/phone.svg";
import papperPlane from "../../img/Solutions Page/WhatWeDo/papper-plane.svg";
import keyboard from "../../img/Solutions Page/WhatWeDo/keyboard.svg";
import VisibilitySensor from "react-visibility-sensor";

const WhatWeDo = () => {
  // Array of data for each section
  const sections = [
    {
      title1: "Artificial",
      title2: "Intelligence",
      description:
        "Build new AI-driven products with machine learning, NLP, and computer vision services.",
      icon: sun,
    },
    {
      title1: "Software",
      title2: "Development",
      description:
        "Expert services for seamless cloud migration, software updates, cybersecurity, and more.",
      icon: shield,
    },
    {
      title1: "App",
      title2: "Solutions",
      description:
        "Tailored app solutions whether for mobile or hybrid to create memorable mobile experiences.",
      icon: phone,
    },
    {
      title1: "Product Design",
      title2: "& Usability",
      description:
        "Experience the impact of combined UX research, design thinking, and UI design services.",
      icon: rocket,
    },
    {
      title1: "Product",
      title2: "Management",
      description:
        "Maximize product success through efficient management and streamlined processes.",
      icon: papperPlane,
    },
    {
      title1: "Platform",
      title2: "Engineering",
      description:
        "Design and build software with robust infrastructure, scalable APIs, and efficient deployment.",
      icon: keyboard,
    },
    {
      title1: "Data",
      title2: "Governance",
      description:
        "Data manipulation, engineering, visualization, and enrichment services to unlock new insights.",
      icon: zip,
    },
    {
      title1: "Quality",
      title2: "Assurance",
      description:
        "Guarantee product excellence through rigorous QA testing and automation.",
      icon: star,
    },
  ];

  return (
    <div className="card-container">
      {/*<h2 className="text-center mb-4">What we do</h2>
       <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <p
          className="text-center card-text"
          style={{ maxWidth: "600px", marginBottom: "64px" }}
        >
          With over 17 years of global design and development experience, we
          have been delivering valued outcomes for organizations with digital
          products and services. Our process is tried and true, focusing on a
          collaborative human centered approach. Following a four step approach:
          Business & Digital Strategy, Experience Design and Managed Services,
          we are able to help you grow better digital experiences.
        </p>
      </div> */}
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {sections.map((section, index) => (
          <VisibilitySensor key={index} partialVisibility>
            {({ isVisible }) => (
              <div key={index} className="col">
                <div
                  className={`card reveal  ${isVisible ? "active" : ""}`}
                  style={{ transitionDelay: `${50 * (index + 1)}ms` }}
                >
                  <div className="card-body">
                    <div>
                      <img
                        src={section.icon}
                        alt={section.title}
                        className="img-fluid"
                        style={{
                          width: "32px",
                          height: "32px",
                          marginBottom: "12px",
                        }}
                      />
                      <h5 className="card-title">{section.title1}</h5>
                      <h5 className="card-title">{section.title2}</h5>
                    </div>

                    <p className="card-text">{section.description}</p>
                  </div>
                </div>
              </div>
            )}
          </VisibilitySensor>
        ))}
      </div>
    </div>
  );
};

export default WhatWeDo;

import React from "react";
import ProcessInfographic from "../../components/ProcessInfographic-white";
import ProcessInfographicMobile from "../../components/ProcessInfographic-mobile-white";

const Process = () => {
  return (
    <>
      <section className="aboutus_process">
        <h2 style={{ height: 66 }} className="reveal aboutus_process_h2">
          Process
        </h2>
        <div className="about_process_processInfographic w1460">
          <ProcessInfographic />
        </div>
        <div className="about_process_processInfographic w414">
          <ProcessInfographicMobile />
        </div>
      </section>
    </>
  );
};

export default Process;

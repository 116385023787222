import React from "react";
import { Container } from "react-bootstrap";

import ContactUsForm from "./ContactUsForm";

const Contact = () => {
  return (
    <>
      <Container style={{ position: "relative" }}>
        <div className="flexContainer aboutus">
          <ContactUsForm />
        </div>
      </Container>

      {/* <ClientsFooter /> */}
    </>
  );
};

export default Contact;

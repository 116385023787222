import { React, useEffect } from "react";
import userflowImg from "../../img/Startup Catalyst/jx_uxui_userflow_design.jpg";
import interactionImg from "../../img/Startup Catalyst/jx_interaction_design_product_design.jpg";
import wireframesImg from "../../img/Startup Catalyst/jx_wireframes_uxui_design_agency.jpg";
import prototypeImg from "../../img/Startup Catalyst/jx_pitch_business_strategy_uxui_prototype_design.jpg";
import testingImg from "../../img/Startup Catalyst/jx_testing_app_ux_research_design.jpg";
import pitchImg from "../../img/Startup Catalyst/jx_pitch_business_strategy_uxui_prototype_design.jpg";
import VisibilitySensor from "react-visibility-sensor";

import StepsMobile from "./StepsMobile";

const Steps = () => {
  useEffect(() => {
    const spanList = document.querySelectorAll(".visibleSensor");
    for (let i = 0; i < spanList.length; i++) {
      spanList[i].style.transitionDelay = `${140 + 20 * i}ms`;
    }
  }, []);
  return (
    <>
      <div className="w414">
        <StepsMobile />
      </div>
      <div className="startup_steps w1460">
        <section>
          <VisibilitySensor
            partialVisibility
            offset={{ bottom: 100 }}
            delayedCall
          >
            {({ isVisible }) => (
              <>
                <div>
                  <h1 className="startup_steps_h1" style={{ marginBottom: 50 }}>
                    <span
                      className={`reveal visibleSensor ${
                        isVisible ? "active" : ""
                      }`}
                    >
                      Everything you need to{" "}
                    </span>{" "}
                    <br />
                    <span
                      className={`reveal visibleSensor ${
                        isVisible ? "active" : ""
                      }`}
                    >
                      turn your idea into
                    </span>{" "}
                    <br />
                    <span
                      className={`reveal visibleSensor ${
                        isVisible ? "active" : ""
                      }`}
                    >
                      a tangible product in 10 days
                    </span>
                  </h1>
                </div>
                {/* FIRST */}
                <div className="startup_steps_row" style={{ marginBottom: 99 }}>
                  <div className="startup_steps_content">
                    <div
                      className="startup_steps_content_container"
                      style={{ marginRight: 102, paddingLeft: 82 }}
                    >
                      <div
                        className={`reveal startup_steps_content_img ${
                          isVisible ? "active" : ""
                        }`}
                        style={{
                          background: `url(${userflowImg})`,
                          height: 329,
                        }}
                      ></div>
                    </div>
                    <div className="startup_steps_content_description">
                      <h6
                        className={`reveal startup_steps_h6 ${
                          isVisible ? "active" : ""
                        }`}
                      >
                        Userflow
                      </h6>
                      <p className="startup_steps_p">
                        <span
                          className={`reveal visibleSensor ${
                            isVisible ? "active" : ""
                          }`}
                        >
                          Our team designs user flows with
                        </span>{" "}
                        <br />
                        <span
                          className={`reveal visibleSensor ${
                            isVisible ? "active" : ""
                          }`}
                        >
                          your customer at the forefront of our
                        </span>{" "}
                        <br />
                        <span
                          className={`reveal visibleSensor ${
                            isVisible ? "active" : ""
                          }`}
                        >
                          minds, ensuring them an intuitive
                        </span>{" "}
                        <br />
                        <span
                          className={`reveal visibleSensor ${
                            isVisible ? "active" : ""
                          }`}
                        >
                          experience when using your
                        </span>{" "}
                        <br />
                        <span
                          className={`reveal visibleSensor ${
                            isVisible ? "active" : ""
                          }`}
                        >
                          product, anticipating their needs
                        </span>{" "}
                        <br />
                        <span
                          className={`reveal visibleSensor ${
                            isVisible ? "active" : ""
                          }`}
                        >
                          and wants. This serves as a solid base
                        </span>{" "}
                        <br />
                        <span
                          className={`reveal visibleSensor ${
                            isVisible ? "active" : ""
                          }`}
                        >
                          for your digital product.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className={`reveal startup_steps_content_borderBox borderBox1 ${
                      isVisible ? "active" : ""
                    }`}
                  ></div>
                </div>
              </>
            )}
          </VisibilitySensor>
          {/* SECOND */}
          <div className="startup_steps_row" style={{ marginBottom: 113 }}>
            <div className="startup_steps_content">
              <div className="startup_steps_content_description">
                <h6 className="reveal startup_steps_h6">Interaction Design</h6>
                <p className="startup_steps_p">
                  <span className="reveal">Refining your users</span> <br />
                  <span className="reveal">experience within your</span> <br />
                  <span className="reveal">products interactions is</span>{" "}
                  <br />
                  <span className="reveal">
                    essential. The interactions will
                  </span>{" "}
                  <br />
                  <span className="reveal">be custom to your users,</span>{" "}
                  <br />
                  <span className="reveal">
                    creating a form fitted design.
                  </span>{" "}
                  <br />
                </p>
              </div>
              <div
                className="startup_steps_content_container"
                style={{ marginLeft: 97 }}
              >
                <div
                  className="reveal startup_steps_content_img"
                  style={{ background: `url(${interactionImg})`, height: 297 }}
                ></div>
              </div>
            </div>
            <div className="reveal startup_steps_content_borderBox borderBox2"></div>
          </div>
          {/* THIRD */}
          <div className="startup_steps_row" style={{ marginBottom: 103 }}>
            <div className="startup_steps_content">
              <div
                className="startup_steps_content_container"
                style={{ marginRight: 94, paddingLeft: 132 }}
              >
                <div
                  className="reveal startup_steps_content_img"
                  style={{ background: `url(${wireframesImg})`, height: 331 }}
                ></div>
              </div>
              <div className="startup_steps_content_description">
                <h6 className="reveal startup_steps_h6">Wireframes</h6>
                <p className="startup_steps_p">
                  <span className="reveal">
                    With the solid base to jumpstart
                  </span>{" "}
                  <br />
                  <span className="reveal">your project, we create</span> <br />
                  <span className="reveal">
                    wireframes in order to articulate
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    every aspect of your product.
                  </span>{" "}
                  <br />
                  <span className="reveal">From Low fidelity to high</span>{" "}
                  <br />
                  <span className="reveal">
                    fidelity we can provide you with
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    the necessary components for
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    investors and developers to
                  </span>{" "}
                  <br />
                  <span className="reveal">work from.</span> <br />
                </p>
              </div>
            </div>
            <div className="reveal startup_steps_content_borderBox borderBox3"></div>
          </div>
          {/* FOURTH */}
          <div className="startup_steps_row" style={{ marginBottom: 100 }}>
            <div className="startup_steps_content">
              <div className="startup_steps_content_description">
                <h6 className="reveal startup_steps_h6">Clickable Prototype</h6>
                <p className="startup_steps_p">
                  <span className="reveal">From the wireframes we </span>
                  <br />
                  <span className="reveal">build a high fidelity </span>
                  <br />
                  <span className="reveal">prototype that we can test </span>
                  <br />
                  <span className="reveal">and eventually present, </span>
                  <br />
                  <span className="reveal">bringing your idea to life in </span>
                  <br />
                  <span className="reveal">its best refined form </span>
                </p>
              </div>
              <div
                className="startup_steps_content_container"
                style={{ marginLeft: 111 }}
              >
                <div
                  className="reveal startup_steps_content_img"
                  style={{ background: `url(${prototypeImg})`, height: 294 }}
                ></div>
              </div>
            </div>
            <div className="reveal startup_steps_content_borderBox borderBox4"></div>
          </div>
          {/* FIFTH */}
          <div className="startup_steps_row" style={{ marginBottom: 54 }}>
            <div className="startup_steps_content">
              <div
                className="startup_steps_content_container"
                style={{ marginRight: 108, paddingLeft: 89 }}
              >
                <div
                  className="reveal startup_steps_content_img"
                  style={{ background: `url(${testingImg})`, height: 343 }}
                ></div>
              </div>
              <div className="startup_steps_content_description">
                <h6 className="reveal startup_steps_h6">Testing</h6>
                <p className="startup_steps_p">
                  <span className="reveal">
                    No project is complete without{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    seeing how the users actually{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    use your product. This is{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    essential to refining your{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    product to make it as seamless{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    as possible for all those who{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    use it. Finding the pain points{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">
                    early means less revisions and{" "}
                  </span>{" "}
                  <br />
                  <span className="reveal">saved time and money. </span>
                </p>
              </div>
            </div>
            <div className="reveal startup_steps_content_borderBox borderBox5"></div>
          </div>
          {/* THE PITCH */}
          <div style={{ paddingLeft: 270 }}>
            <h1 className="reveal startup_steps_pitch">The Pitch</h1>
          </div>
          <img
            className="reveal"
            src={pitchImg}
            alt=""
            width="964"
            height="419"
            style={{
              borderRadius: "5px",
              marginTop: 48,
              marginBottom: 48,
              alignSelf: "center",
            }}
          />
        </section>
      </div>
    </>
  );
};

export default Steps;

import React from "react";
import { Container } from "react-bootstrap";

import Heading from "./Heading";
import Center from "./Center";
import Process from "./Process";
import ClientsFooter from "../../components/ClientsFooter";
import WhatWeDo from "./WhatWeDo";

const Solutions = () => {
  return (
    <>
      <Container>
        <div
          className="flexContainer"
          style={{ marginBottom: 119, marginTop: 160 }}
        >
          <Heading />
          <WhatWeDo />
          <Center />
          <Process />
        </div>
      </Container>
      <ClientsFooter />
    </>
  );
};

export default Solutions;

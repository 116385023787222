import React from "react";
import VisibilitySensor from "react-visibility-sensor";

const Heading = () => {
  
  return (
    <div>
      <VisibilitySensor partialVisibility offset={{ bottom: 100 }} delayedCall>
        {({ isVisible }) => (
          <>
            <h1
              className={`reveal solutions_heading_h1 ${
                isVisible ? "active" : ""
              }`}
            >
              What we do
            </h1>
            <p className="solutions_heading_description w1460">
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                With over 17 years of global design and development experience,
                we have been
              </span>
              <br />
              <span
                style={{ transitionDelay: "40ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                delivering valued outcomes for organizations with digital
                products and services.{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "60ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                Our process is tried and true, focusing on a collaborative human
                centered{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "80ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                approach. Following a four step approach: Business & Digital
                Strategy,{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "100ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                Experience Design and Managed Services, we are able to help you
                grow{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "120ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                better digital experiences.
              </span>
            </p>
            <p className="solutions_heading_description w414">
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                With over 17 years of global design and{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                development experience, we have been{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                delivering valued outcomes for{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                organizations with digital products and{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                services. Our process is tried and true,{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                focusing on a collaborative human{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                centered approach. Following a four step{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                approach: Business & Digital Strategy,{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                Experience Design and Managed{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                Services, we are able to help you grow
              </span>
              <br />
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal solutions_heading_span ${
                  isVisible ? "active" : ""
                }`}
              >
                better digital experiences.
              </span>
              <br />
            </p>
          </>
        )}
      </VisibilitySensor>
    </div>
  );
};

export default Heading;

import React from "react";
import VisibilitySensor from "react-visibility-sensor";

const Heading = () => {
  return (
    <VisibilitySensor partialVisibility offset={{ bottom: 100 }} delayedCall>
      {({ isVisible }) => (
        <>
          <div className="">
            <h1 className={`reveal startup_h1 ${isVisible ? "active" : ""}`}>
              Startup Catalyst
            </h1>
            <p className="startup_description w1460">
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                Our team helps turn your innovative ideas into a reality. We
                understand that
              </span>
              <br />
              <span
                style={{ transitionDelay: "40ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                most people get overwhelmed with where or how to start, that’s
                why we take a
              </span>
              <br />
              <span
                style={{ transitionDelay: "60ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                holistic approach and in our Startup Catalyst process you will
                have all that you
              </span>
              <br />
              <span
                style={{ transitionDelay: "80ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                need for a great pitch, in just 10 days. We work with you every
                step of the way,
              </span>
              <br />
              <span
                style={{ transitionDelay: "100ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                from wireframes to final product delivery and beyond.
              </span>
            </p>
            <p className="startup_description w414">
              <span
                style={{ transitionDelay: "20ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                Our team helps turn your innovative ideas
              </span>
              <br />
              <span
                style={{ transitionDelay: "30ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                into a reality. We understand that most{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "40ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                people get overwhelmed with where or{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "50ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                how to start, that’s why we take a{" "}
              </span>
              <br />
              holistic
              <span
                style={{ transitionDelay: "60ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                approach and in our Startup Catalyst{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "70ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                process you will have all that you need{" "}
              </span>
              <br />
              for
              <span
                style={{ transitionDelay: "80ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                a great pitch, in just 10 days. We work
              </span>
              <br />
              <span
                style={{ transitionDelay: "100ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                with you every step of the way, from{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "120ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                wireframes to final product delivery and{" "}
              </span>
              <br />
              <span
                style={{ transitionDelay: "140ms" }}
                className={`reveal startup_span ${isVisible ? "active" : ""}`}
              >
                beyond.{" "}
              </span>
              <br />
            </p>
          </div>
        </>
      )}
    </VisibilitySensor>
  );
};

export default Heading;

import { React, useRef } from "react";
import jx_design_proocess_01 from "../img/process icons black/jx_design_proocess-01.svg";
import jx_design_proocess_02 from "../img/process icons black/jx_design_proocess-02.svg";
import jx_design_proocess_03 from "../img/process icons black/jx_design_proocess-03.svg";
import jx_design_proocess_04 from "../img/process icons black/jx_design_proocess-04.svg";
import jx_design_proocess_05 from "../img/process icons black/jx_design_proocess-05.svg";
import Discovery from "../img/discovery.svg";
import Define from "../img/define.svg";
import Design from "../img/design.svg";
import Deliver from "../img/deliver.svg";
import Iterate from "../img/iterate.svg";

const ProcessInfographic = () => {
  const discoveryRef = useRef(null);
  const defineRef = useRef(null);
  const designRef = useRef(null);
  const deliverRef = useRef(null);
  const iterateRef = useRef(null);
  return (
    <>
      <div className="processInfographic_container ">
        {/* SECTION: DISCOVERY */}
        <div
          ref={discoveryRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
          }}
          className="processInfographic_outline_container discovery"
        >
          <div className="processInfographic_outline_red processInfographic_outline_red_1"></div>
          <div className="processInfographic_outline_red processInfographic_outline_red_2"></div>
          <div className="reveal processInfographic_outline processInfographic_outline_1"></div>
          <img
            className="reveal processInfographic_wave processInfographic_outline_1_1 "
            src={Discovery}
            alt="Discovery Icon"
            style={{ paddingTop: "100px" }}
          />
          <div className="processInfographic_info processInfographic_info_1">
            <img
              className="reveal "
              src={jx_design_proocess_01}
              alt="Discovery Icon"
            />
            <h5 className="reveal processInfographic_outline_h5">Discovery</h5>
            <p className="processInfographic_outline_p">
              <span className="reveal">Identify the problem</span>
              <br />
              <span className="reveal">at hand, and who</span>
              <br />
              <span className="reveal">the solution is being</span>
              <br />
              <span className="reveal">designed for, to create</span>
              <br />
              <span className="reveal">a tailored experience.</span>
            </p>
          </div>
        </div>
        {/* SECTION: DEFINE */}
        <div
          ref={defineRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
            discoveryRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
          }}
          className="processInfographic_outline_container"
        >
          <div className="processInfographic_outline_red processInfographic_outline_red_3"></div>
          <div className="processInfographic_outline_red processInfographic_outline_red_4"></div>
          <div className="reveal  processInfographic_outline processInfographic_outline_2"></div>
          <img
            className="reveal processInfographic_wave processInfographic_outline_2_1 "
            src={Define}
            alt="Discovery Icon"
            style={{ paddingTop: "100px" }}
          />
          <div className="processInfographic_info processInfographic_info_2">
            <img
              className="reveal "
              src={jx_design_proocess_04}
              alt="Define Icon"
            />
            <h5 className="reveal processInfographic_outline_h5">Define</h5>
            <p className="processInfographic_outline_p">
              <span className="reveal">We then hone in on</span>
              <br />
              <span className="reveal">the problem and</span>
              <br />
              <span className="reveal">develop an in depth</span>
              <br />
              <span className="reveal">problem statement,</span>
              <br />
              <span className="reveal">making a plan on</span>
              <br />
              <span className="reveal">approach.</span>
            </p>
          </div>
        </div>
        {/* DESIGN */}
        <div
          ref={designRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
            discoveryRef.current.classList.add("hover");
            defineRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
            defineRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
            defineRef.current.classList.remove("hover");
          }}
          className="processInfographic_outline_container"
        >
          <div className="processInfographic_outline_red processInfographic_outline_red_5"></div>
          <div className="processInfographic_outline_red processInfographic_outline_red_6"></div>
          <div className="reveal processInfographic_outline processInfographic_outline_3"></div>
          <img
            className="reveal processInfographic_wave processInfographic_outline_3_1 "
            src={Design}
            alt="Discovery Icon"
            style={{ paddingTop: "100px" }}
          />
          <div className="processInfographic_info processInfographic_info_3">
            <img
              className="reveal "
              src={jx_design_proocess_05}
              alt="Design Icon"
            />
            <h5 className="reveal processInfographic_outline_h5">Design</h5>
            <p className="processInfographic_outline_p">
              <span className="reveal">After ideation, the</span>
              <br />
              <span className="reveal">challenges are</span>
              <br />
              <span className="reveal">addressed through</span>
              <br />
              <span className="reveal">design, creating a</span>
              <br />
              <span className="reveal">tangible product, able to</span>
              <br />
              <span className="reveal">be tested and refined</span>
            </p>
          </div>
        </div>
        {/* Deliver */}
        <div
          ref={deliverRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
            discoveryRef.current.classList.add("hover");
            defineRef.current.classList.add("hover");
            designRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
            defineRef.current.classList.remove("hover");
            designRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
            defineRef.current.classList.remove("hover");
            designRef.current.classList.remove("hover");
          }}
          className="processInfographic_outline_container"
        >
          <div className="processInfographic_outline_red processInfographic_outline_red_7"></div>
          <div className="processInfographic_outline_red processInfographic_outline_red_8"></div>
          <div className="reveal processInfographic_outline processInfographic_outline_4"></div>
          <img
            className="reveal processInfographic_wave processInfographic_outline_4_1 "
            src={Deliver}
            alt="Deliver Wave"
            style={{ paddingTop: "100px" }}
          />
          <div className="processInfographic_info processInfographic_info_4">
            <img
              className="reveal "
              src={jx_design_proocess_02}
              alt="Deliver Icon"
            />
            <h5 className="reveal processInfographic_outline_h5">Deliver</h5>
            <p className="processInfographic_outline_p">
              <span className="reveal">We bring the solution</span>
              <br />
              <span className="reveal">to you, implementing</span>
              <br />
              <span className="reveal">a refined solution that</span>
              <br />
              <span className="reveal">will meet the needs of</span>
              <br />
              <span className="reveal">you and your user.</span>
            </p>
          </div>
        </div>
        {/* Iterate */}
        <div
          ref={iterateRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
            discoveryRef.current.classList.add("hover");
            defineRef.current.classList.add("hover");
            designRef.current.classList.add("hover");
            deliverRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
            defineRef.current.classList.remove("hover");
            designRef.current.classList.remove("hover");
            deliverRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            discoveryRef.current.classList.remove("hover");
            defineRef.current.classList.remove("hover");
            designRef.current.classList.remove("hover");
            deliverRef.current.classList.remove("hover");
          }}
          className="processInfographic_outline_container"
        >
          <div className="processInfographic_outline_red processInfographic_outline_red_9"></div>
          <div className="processInfographic_outline_red processInfographic_outline_red_10"></div>
          <div className="reveal">
            <div className="processInfographic_outline processInfographic_outline_5"></div>
            <div className="processInfographic_outline processInfographic_outline_extra"></div>
          </div>
          <img
            className="reveal processInfographic_wave processInfographic_outline_5_1 "
            src={Iterate}
            alt="Iterate Wave"
            style={{ paddingTop: "100px" }}
          />
          <div className="processInfographic_info processInfographic_info_5">
            <img
              className="reveal "
              src={jx_design_proocess_03}
              alt="Iterate Icon"
            />
            <h5 className="reveal processInfographic_outline_h5">Iterate</h5>
            <p className="processInfographic_outline_p">
              <span className="reveal">Continuous</span>
              <br />
              <span className="reveal">improvements are</span>
              <br />
              <span className="reveal">made, ensuring an</span>
              <br />
              <span className="reveal">effective and flexible</span>
              <br />
              <span className="reveal">solution, tailored to</span>
              <br />
              <span className="reveal">your users</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessInfographic;

import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./CaseStudiesMap.css";

const CaseStudiesMap = (props) => {
  return (
    <>
      {props.cases.map((el, idx) => (
        <section
          key={idx}
          style={{
            ...el.style,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
          onMouseOver={(ev) => {
            let subtitle = ev.currentTarget.querySelector(
              ".homepage_cases_subtitle"
            );
            subtitle.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            let subtitle = ev.currentTarget.querySelector(
              ".homepage_cases_subtitle"
            );
            subtitle.classList.remove("hover");
          }}
        >
          <VisibilitySensor partialVisibility delayedCall>
            {({ isVisible }) => (
              <>
                <div
                  className={`reveal ${isVisible ? "active" : ""}`}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    aria-label={`Read more about the ${el.title} case study`}
                    href={"/case-studies/" + el.title}
                    style={{ width: "100%" }}
                  >
                    {el.videoUri ? (
                      <video
                        className="grow homepage_cases_video"
                        src={el.videoUri}
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                          borderRadius: "8px",
                          width: "100%",
                          height: "auto",
                          maxHeight: "400px",
                        }}
                      />
                    ) : (
                      <LazyLoadImage
                        effect="blur"
                        alt={el.title}
                        className="grow homepage_cases_img"
                        src={el.img}
                        style={{
                          borderRadius: "8px",
                          width: "100%",
                          height: "auto",
                          maxHeight: "400px",
                        }}
                      />
                    )}
                  </a>
                </div>
                <a
                  aria-label={`Read more about the ${el.title} case study`}
                  href={"/case-studies/" + el.title}
                >
                  <h4
                    className="reveal homepage_cases_title"
                    style={{ textAlign: "center", margin: "10px 0" }}
                  >
                    <span className="blueHover">{el.title}</span>
                  </h4>
                </a>
                <h5
                  className="homepage_cases_subtitle"
                  style={{ textAlign: "center", margin: "5px 0" }}
                >
                  {el.subtitle}
                </h5>
              </>
            )}
          </VisibilitySensor>
        </section>
      ))}
    </>
  );
};

export default CaseStudiesMap;

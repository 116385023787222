import { React, useRef } from "react";
import userflowImg from "../../img/Startup Catalyst/jx_uxui_userflow_design.jpg";
// import interactionImg from "../../img/Startup Catalyst/jx_interaction_design_product_design.jpg";
// import wireframesImg from "../../img/Startup Catalyst/jx_wireframes_uxui_design_agency.jpg";
// import prototypeImg from "../../img/Startup Catalyst/jx_pitch_business_strategy_uxui_prototype_design.jpg";
// import testingImg from "../../img/Startup Catalyst/jx_testing_app_ux_research_design.jpg";
import pitchImg from "../../img/Startup Catalyst/jx_pitch_business_strategy_uxui_prototype_design.jpg";

const StepsMobile = () => {
  const userflowRef = useRef(null);
  const interactionRef = useRef(null);
  const wireframesRef = useRef(null);
  const prototypeRef = useRef(null);
  const testingRef = useRef(null);
  return (
    <>
      <div className="startup_steps">
        <div className="w414">
          <h1 className="startup_steps_h1" style={{ marginBottom: 50 }}>
            <span className="reveal">Everything you need to turn</span> <br />
            <span className="reveal">your idea into a tangible</span> <br />
            <span className="reveal">product in 10 days</span>
          </h1>
        </div>
        {/* FIRST */}
        <div
          ref={userflowRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
          }}
          className="startup_steps_row"
          style={{ marginBottom: 99 }}
        >
          <div className="startup_steps_content">
            <div
              className="startup_steps_content_container"
              style={{ marginRight: 102, paddingLeft: 82 }}
            >
              <div
                className="reveal startup_steps_content_img"
                style={{ background: `url(${userflowImg})`, height: 329 }}
              ></div>
            </div>
            <div
              className="startup_steps_content_description w414"
              style={{ textAlign: "right" }}
            >
              <h6 className="reveal startup_steps_h6">Userflow</h6>
              <p className="startup_steps_p">
                <span className="reveal">Our team designs user flows with</span>{" "}
                <br />
                <span className="reveal">
                  your customer at the forefront of our
                </span>{" "}
                <br />
                <span className="reveal">
                  minds, ensuring them an intuitive
                </span>{" "}
                <br />
                <span className="reveal">experience when using your</span>{" "}
                <br />
                <span className="reveal">
                  product, anticipating their needs
                </span>{" "}
                <br />
                <span className="reveal">
                  and wants. This serves as a solid base
                </span>{" "}
                <br />
                <span className="reveal">for your digital product.</span>
              </p>
            </div>
          </div>
          <div className="reveal startup_steps_content_borderBox borderBox1"></div>
          <div className="borderBox1 processInfographic_outline_red "></div>
        </div>
        {/* SECOND */}
        <div
          ref={interactionRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
            userflowRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
          }}
          className="startup_steps_row"
        >
          <div className="startup_steps_content">
            <div
              className="startup_steps_content_description w414"
              style={{ textAlign: "left" }}
            >
              <h6 className="reveal startup_steps_h6">Interaction Design</h6>
              <p className="startup_steps_p">
                <span className="reveal">Refining your users</span> <br />
                <span className="reveal">experience within your</span> <br />
                <span className="reveal">products interactions is</span> <br />
                <span className="reveal">
                  essential. The interactions will
                </span>{" "}
                <br />
                <span className="reveal">be custom to your users,</span> <br />
                <span className="reveal">
                  creating a form fitted design.
                </span>{" "}
                <br />
              </p>
            </div>
          </div>
          <div className="reveal startup_steps_content_borderBox borderBox2"></div>
          <div className="borderBox2 processInfographic_outline_red "></div>
        </div>
        {/* THIRD */}
        <div
          ref={wireframesRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
            userflowRef.current.classList.add("hover");
            interactionRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
            interactionRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
            interactionRef.current.classList.remove("hover");
          }}
          className="startup_steps_row"
          style={{ marginBottom: 103 }}
        >
          <div className="startup_steps_content">
            <div
              className="startup_steps_content_description w414"
              style={{ textAlign: "right" }}
            >
              <h6 className="reveal startup_steps_h6">Wireframes</h6>
              <p className="startup_steps_p">
                <span className="reveal">With the solid base to jumpstart</span>{" "}
                <br />
                <span className="reveal">your project, we create</span> <br />
                <span className="reveal">
                  wireframes in order to articulate
                </span>{" "}
                <br />
                <span className="reveal">
                  every aspect of your product.
                </span>{" "}
                <br />
                <span className="reveal">From Low fidelity to high</span> <br />
                <span className="reveal">
                  fidelity we can provide you with
                </span>{" "}
                <br />
                <span className="reveal">
                  the necessary components for
                </span>{" "}
                <br />
                <span className="reveal">investors and developers to</span>{" "}
                <br />
                <span className="reveal">work from.</span> <br />
              </p>
            </div>
          </div>
          <div className="reveal startup_steps_content_borderBox borderBox3"></div>
          <div className="borderBox3 processInfographic_outline_red "></div>
        </div>
        {/* FOURTH */}
        <div
          ref={prototypeRef}
          onMouseOver={(ev) => {
            ev.currentTarget.classList.add("hover");
            userflowRef.current.classList.add("hover");
            interactionRef.current.classList.add("hover");
            wireframesRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
            interactionRef.current.classList.remove("hover");
            wireframesRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
            interactionRef.current.classList.remove("hover");
            wireframesRef.current.classList.remove("hover");
          }}
          className="startup_steps_row"
          style={{ marginBottom: 100 }}
        >
          <div className="startup_steps_content">
            <div
              className="startup_steps_content_description w414"
              style={{ textAlign: "left" }}
            >
              <h6 className="reveal startup_steps_h6">Clickable Prototype</h6>
              <p className="startup_steps_p">
                <span className="reveal">From the wireframes we </span>
                <br />
                <span className="reveal">build a high fidelity </span>
                <br />
                <span className="reveal">prototype that we can test </span>
                <br />
                <span className="reveal">and eventually present, </span>
                <br />
                <span className="reveal">bringing your idea to life in </span>
                <br />
                <span className="reveal">its best refined form </span>
              </p>
            </div>
          </div>
          <div className="reveal startup_steps_content_borderBox borderBox4"></div>
          <div className="borderBox4 processInfographic_outline_red "></div>
        </div>
        {/* FIFTH */}
        <div
          ref={testingRef}
          onMouseOver={(ev) => {
            console.log("over");
            ev.currentTarget.classList.add("hover");
            userflowRef.current.classList.add("hover");
            interactionRef.current.classList.add("hover");
            wireframesRef.current.classList.add("hover");
            prototypeRef.current.classList.add("hover");
          }}
          onMouseLeave={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
            interactionRef.current.classList.remove("hover");
            wireframesRef.current.classList.remove("hover");
            prototypeRef.current.classList.remove("hover");
          }}
          onMouseOut={(ev) => {
            ev.currentTarget.classList.remove("hover");
            userflowRef.current.classList.remove("hover");
            interactionRef.current.classList.remove("hover");
            wireframesRef.current.classList.remove("hover");
            prototypeRef.current.classList.remove("hover");
          }}
          className="startup_steps_row"
          style={{ marginBottom: 54 }}
        >
          <div className="startup_steps_content">
            <div
              className="startup_steps_content_description w414"
              style={{ textAlign: "right" }}
            >
              <h6 className="reveal startup_steps_h6">Testing</h6>
              <p className="startup_steps_p">
                <span className="reveal">No project is complete without </span>{" "}
                <br />
                <span className="reveal">
                  seeing how the users actually{" "}
                </span>{" "}
                <br />
                <span className="reveal">use your product. This is </span>{" "}
                <br />
                <span className="reveal">essential to refining your </span>{" "}
                <br />
                <span className="reveal">
                  product to make it as seamless{" "}
                </span>{" "}
                <br />
                <span className="reveal">
                  as possible for all those who{" "}
                </span>{" "}
                <br />
                <span className="reveal">
                  use it. Finding the pain points{" "}
                </span>{" "}
                <br />
                <span className="reveal">
                  early means less revisions and{" "}
                </span>{" "}
                <br />
                <span className="reveal">saved time and money. </span>
              </p>
            </div>
          </div>
          <div className="reveal startup_steps_content_borderBox borderBox5"></div>
          <div className="borderBox5 processInfographic_outline_red "></div>
          <div className="borderBox6 processInfographic_outline_red "></div>
        </div>
        {/* THE PITCH */}
        <div>
          <h1 className="reveal startup_steps_pitch">The Pitch</h1>
        </div>
        <img
          className="reveal"
          src={pitchImg}
          alt=""
          width="964"
          height="419"
          style={{ borderRadius: "5px", marginTop: 48, marginBottom: 48 }}
        />
      </div>
    </>
  );
};

export default StepsMobile;

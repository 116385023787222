import { React, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import Capabilities from "./Capabilities";

import uiuxImg from "../../img/Solutions Page/Design/jx_design_services_design_uxui_product_design.jpg";
import serviceImg from "../../img/Solutions Page/Design/jx_design_services_design_service_design.jpg";
import businessImg from "../../img/Solutions Page/Design/jx_design_business_model_canvas_design_uxui_product_design.jpg";
import visualImg from "../../img/Solutions Page/Design/jx_design_services_design_visual_product_design.jpg";

const Design = () => {
  const sub = {
    uiux: {
      content: [
        {
          title: "UX Audit",
          description: `
            <span>UX Audits are an analysis done to </span><br/>
            <span>understand the current state of your </span><br/>
            <span>digital service and product experience, </span><br/>
            <span>helping you recognize your customers’ </span><br/>
            <span>pain points to innovate a better user </span><br/>
            <span>experience to shape your obstacles </span><br/>
            <span>into opportunities.</span>`,
        },
        {
          title: `User Research`,
          description: `
          <span>Projects need to start with relevant data,</span><br/>
          <span>which is why we conduct user research.</span><br/>
          <span>Including, but not limited to, interviews,</span><br/>
          <span>market research, and user interface</span><br/>
          <span>testing, we are able to focus on your</span><br/>
          <span>users needs, producing a product</span><br/>
          <span>or service that is customer centric.</span><br/>`,
        },
        {
          title: "Design Systems",
          description: `
          <span>Design Systems are a set of guidelines</span><br/> 
          <span>and assets to maximise consistency and</span><br/> 
          <span>efficiency within your organisation. You</span><br/> 
          <span>will be able to test less, produce more,</span><br/> 
          <span>while creating a brand tone and voice </span><br/>
          <span>that users will find familiar and trust.</span><br/>`,
        },
        {
          title: `Component Library`,
          description: `
          <span>A component library provides a repository</span><br/>
          <span>containing reusable components, like</span><br/>
          <span>buttons and accordions, all in one place.</span><br/>
          <span>Having these components set and ready</span><br/>
          <span>will bring better collaboration and efficiency</span><br/>
          <span>between different areas of your team.</span><br/>`,
        },
        {
          title: "Rapid Prototyping",
          description: `
          <span>Visualising ideas can be a long and</span><br/>  
          <span>expensive process, but with rapid</span><br/>  
          <span>prototyping you are able to get tangible</span><br/> 
          <span>prototypes to analyze, test, and identify</span><br/> 
          <span>user pain points that can occur, all at a</span><br/> 
          <span>faster rate. In short, you get to your final</span><br/> 
          <span>design quicker.</span><br/>`,
        },
        {
          title: `Usability Testing`,
          description: `
          <span>After creating a prototype the work isn’t</span><br/>
          <span>done until we know your users’ needs are</span><br/>
          <span>met. Usability testing allows us to test for</span><br/>
          <span>our usability goals, and find pain points</span><br/>
          <span>that need to be addressed and resolved.</span><br/>`,
        },
        {
          title: `UX Writing`,
          description: `
          <span>Your organization tone and voice</span><br/>
          <span>doesn’t stop at visual design. Keeping</span><br/>
          <span>your user at the forefront, UX writing</span><br/>
          <span>brings consistency and usability to your</span><br/>
          <span>content, connecting them to your</span><br/>
          <span>product. With data and testing we are</span><br/>
          <span>able to customize the experience of your</span><br/>
          <span>product through copy.</span><br/>`,
        },
        {
          title: `UI Design`,
          description: `
          <span>User experience design is the</span><br/>
          <span>fundamental process for all digital</span><br/>
          <span>products. It leads us to design products</span><br/>
          <span>that specifically fits your customer needs</span><br/>
          <span>by understanding how they use your</span><br/>
          <span>product. This will create improved</span><br/>
          <span>engagement through a personalized</span><br/>
          <span>user interface design framework.</span><br/>`,
        },
      ],
      transitionDelay: `160ms`,
      height: 1271,
      mobileHeight: 1530,
    },
    service: {
      content: [
        {
          title: "Persona",
          description: `
          <span>Personas are character profiles that </span><br/>
          <span>represent your users’ unique behaviours, </span><br/>
          <span>needs, and goals based on our research.  </span><br/>
          <span>Using personas we can identify your </span><br/>
          <span>target user’s journey, expectations, and </span><br/>
          <span>pain points. Stepping in their shoes, we </span><br/>
          <span>can identify the scope of the design </span><br/>
          <span>problem and how to effectively address </span><br/>
          <span>it to create a successful  product.</span><br/>`,
        },
        {
          title: `Customer Journey Map`,
          description: `
          <span>By plotting your users journey from start</span><br/>
          <span>to finish, their narrative will unfold,</span><br/>
          <span>revealing habits, pain points and distinct</span><br/>
          <span>interactions. Evaluation of the journey</span><br/>
          <span>through their point of view will bring a</span><br/>
          <span>deeper understanding of how your</span><br/>
          <span>product achieves its intended goals or</span><br/>
          <span>how to improve their experience.</span><br/>`,
        },
        {
          title: "Service Blueprint",
          description: `
          <span>Service blueprints not only reveal the</span><br/> 
          <span>surface experience of your organization,</span><br/> 
          <span>but it also goes into a comprehensive</span><br/> 
          <span>path of core functions. This creates</span><br/> 
          <span>a front to back narrative, where</span><br/> 
          <span>evaluations are done to hone in</span><br/>
          <span>on the overall journey, revealing areas</span><br/>
          <span>that may need to be enhanced</span><br/>`,
        },
      ],
      transitionDelay: `180ms`,
      height: 751,
    },
    business: {
      content: [
        {
          title: "Business Model Canvas",
          description: `
            <span>This management tool showcases your</span><br/>
            <span>business model and its key components.</span><br/>
            <span>in one artefact. A place of truth. It helps</span><br/>
            <span>to provoke deeper thinking to identify</span><br/>
            <span>what we generally miss. Useful for both</span><br/>
            <span>new and existing businesses, your goal</span><br/>
            <span>and plans will be cohesively presented</span><br/>
            <span>for your team.</span><br/>`,
        },
        {
          title: `Value Proposition Canvas`,
          description: `
          <span>After defining your customers, the value</span><br/>
          <span>proposition canvas defines their tasks</span><br/>
          <span>and pain points related to those tasks.</span><br/>
          <span>We will define how your project or</span><br/>
          <span>service remedies those pain points,</span><br/>
          <span>creating a value proposition that helps</span><br/>
          <span>you create a product or service that you</span><br/>
          <span>customers want to use</span><br/>`,
        },
        {
          title: "Stakeholder Mapping",
          description: `
          <span>Focusing on who your business </span><br/> 
          <span>stakeholders are, we can create a visual</span><br/> 
          <span>network of all who are involved in your </span><br/>
          <span>product or service, and what their needs </span><br/>
          <span>are and how to address them within the </span><br/>
          <span>network of contributors. This ensures </span><br/>
          <span>that your design addresses these </span><br/>
          <span>touchpoints for human centred </span><br/>
          <span>user experiences. </span><br/>`,
        },
      ],
      transitionDelay: `200ms`,
      height: 723,
    },
    visual: {
      title: `How do I get a proposal for my project?`,
      content: [
        {
          title: `Product Visualisation`,
          description: `
          <span>Creating visuals for your product bridges</span><br/>
          <span>the gap for your customer, allowing</span><br/>
          <span>them to absorb the information you’re</span><br/>
          <span>presenting swiftly, which is essential in</span><br/>
          <span>today’s digital industry. With compelling</span><br/>
          <span>images and an immersive product</span><br/>
          <span>representation, your customers will have</span><br/>
          <span>an amplified experience.</span><br/>`,
        },
        {
          title: `Data Visualisation`,
          description: `
          <span>Data communication done visually can</span><br/>
          <span>refine a user’s experience, providing</span><br/>
          <span>them with clarity simply and quickly. We</span><br/>
          <span>will present your important data with</span><br/>
          <span>your user in mind, creating a seamless</span><br/>
          <span>experience between your data and your</span><br/>
          <span>brand’s product/ service offering. </span><br/>`,
        },
      ],
      transitionDelay: `220ms`,
      height: 258,
      mobileHeight: 450,
    },
  };

  const handleToggle = (ev) => {
    if (activeCollapsible === ev) setActiveCollapsible(null);
    else setActiveCollapsible(ev);
  };

  const [activeCollapsible, setActiveCollapsible] = useState(null);
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <>
      {/* <DesignMobile /> */}
      <div className="">
        {/* <div className="w1460"> */}
        {/* section-1 */}
        <VisibilitySensor
          partialVisibility
          offset={{ bottom: 100 }}
          active={!viewPortEntered}
          onChange={(isVisible) => {
            if (isVisible) {
              setViewPortEntered(true);
            }
          }}
          delayedCall
        >
          {({ isVisible }) => (
            <div>
              <section className="solutions_center_content_section">
                <div style={{ marginRight: 85.5 }}>
                  <h5
                    className={`reveal solutions_center_content_h5 ${
                      isVisible ? "active" : ""
                    }`}
                  >
                    UI/UX Design
                  </h5>
                  <p
                    className="solutions_center_content_p"
                    style={{ marginBottom: 30 }}
                  >
                    <span
                      style={{ transitionDelay: "20ms" }}
                      className={`reveal ${isVisible ? "active" : ""}`}
                    >
                      User experience design is the fundamental
                    </span>
                    <span
                      style={{ transitionDelay: "40ms" }}
                      className={`reveal ${isVisible ? "active" : ""}`}
                    >
                      process for all digital products. It leads us to
                    </span>
                    <span
                      style={{ transitionDelay: "60ms" }}
                      className={`reveal ${isVisible ? "active" : ""}`}
                    >
                      design products that specifically fits your
                    </span>
                    <span
                      style={{ transitionDelay: "80ms" }}
                      className={`reveal ${isVisible ? "active" : ""}`}
                    >
                      customer needs by understanding how they use
                    </span>
                    <span
                      style={{ transitionDelay: "100ms" }}
                      className={`reveal ${isVisible ? "active" : ""}`}
                    >
                      your product. This will create improved
                    </span>
                    <span
                      style={{ transitionDelay: "120ms" }}
                      className={`reveal ${isVisible ? "active" : ""}`}
                    >
                      engagement through a personalized user
                    </span>
                    <span
                      style={{ transitionDelay: "140ms" }}
                      className={`reveal ${isVisible ? "active" : ""}`}
                    >
                      interface design framework.
                    </span>
                  </p>
                  <div>
                    <h6
                      onClick={() => handleToggle(1)}
                      className="blueHover solutions_center_content_capabilities_h6"
                    >
                      <span className="reveal" style={{ marginRight: 25 }}>
                        Capabilities
                      </span>
                      <span className="reveal">
                        <i
                          className={`thinIcon fa fa-chevron-down fa-2xs ${
                            activeCollapsible === 1 ? "isCollapsed" : ""
                          }`}
                        ></i>
                      </span>
                    </h6>
                  </div>
                  <div
                    className={`w414 solutions_center_content_capabilities_description ${
                      activeCollapsible === 1 ? "isCollapsed" : ""
                    }`}
                    style={{
                      height:
                        activeCollapsible === 1 ? sub.uiux.mobileHeight : 0,
                    }}
                  >
                    <Capabilities content={sub.uiux.content} />
                  </div>
                </div>
                <div
                  style={{ transitionDelay: "40ms", background: "#D9D9D9" }}
                  className={`reveal solutions_center_content_img ${
                    isVisible ? "active" : ""
                  }`}
                >
                  <img
                    className="solutions_center_content_img"
                    src={uiuxImg}
                    alt="UIUX Design"
                  />
                </div>
              </section>
              <div
                className={`w1460 solutions_center_content_capabilities_description ${
                  activeCollapsible === 1 ? "isCollapsed" : ""
                }`}
                style={{
                  paddingTop: 81,
                  height: activeCollapsible === 1 ? sub.uiux.height : 0,
                }}
              >
                <Capabilities content={sub.uiux.content} />
              </div>
            </div>
          )}
        </VisibilitySensor>

        {/* section-2 */}
        <div>
          <section className="solutions_center_content_section">
            <div className="w1460">
              <div
                className="reveal solutions_center_content_img"
                style={{ background: "#D9D9D9", marginRight: 78 }}
              >
                <img
                  className="solutions_center_content_img"
                  src={serviceImg}
                  alt="Service Design Blueprint"
                />
              </div>
            </div>
            <div>
              <h5 className="reveal solutions_center_content_h5">
                Service Design
              </h5>
              <p
                className="solutions_center_content_p"
                style={{ marginBottom: 30 }}
              >
                <span className="reveal">
                  Service design is how an organization connects
                </span>
                <br />
                <span className="reveal">
                  the dots between user experience in their
                </span>
                <br />
                <span className="reveal">
                  service offering and how the internal process
                </span>
                <br />
                <span className="reveal">
                  supports it. Through our agile design strategy,
                </span>
                <br />
                <span className="reveal">
                  we identify the needs of your current service
                </span>
                <br />
                <span className="reveal">
                  systems, suggest areas for improvement, and
                </span>
                <br />
                <span className="reveal">
                  add to the interactivity of your internal
                </span>
                <br />
                <span className="reveal">
                  processes, including room for growth.
                </span>
              </p>
              <h6
                onClick={() => handleToggle(2)}
                className="blueHover solutions_center_content_capabilities_h6"
              >
                <span className="reveal" style={{ marginRight: 25 }}>
                  Capabilities
                </span>
                <span className="reveal">
                  <i
                    className={`thinIcon fa fa-chevron-down fa-2xs ${
                      activeCollapsible === 2 ? "isCollapsed" : ""
                    }`}
                  ></i>
                </span>
              </h6>
              <div
                className={`w414 solutions_center_content_capabilities_description ${
                  activeCollapsible === 2 ? "isCollapsed" : ""
                }`}
                style={{
                  height: activeCollapsible === 2 ? sub.service.height : 0,
                }}
              >
                <Capabilities content={sub.service.content} />
              </div>
            </div>
            <div className="w414">
              <div
                className="reveal solutions_center_content_img"
                style={{ background: "#D9D9D9", marginRight: 78 }}
              >
                <img
                  className="solutions_center_content_img"
                  src={serviceImg}
                  alt="Service Design Blueprint"
                />
              </div>
            </div>
          </section>
          <div
            className={`w1460 solutions_center_content_capabilities_description w1460 ${
              activeCollapsible === 2 ? "isCollapsed" : ""
            }`}
            style={{
              paddingTop: 81,
              height: activeCollapsible === 2 ? sub.service.height : 0,
            }}
          >
            <Capabilities content={sub.service.content} />
          </div>
        </div>
        {/* section-3 */}
        <div>
          <section className="solutions_center_content_section">
            <div style={{ marginRight: 104.5 }}>
              <h5 className="reveal solutions_center_content_h5">
                Business Design
              </h5>
              <p
                className="solutions_center_content_p"
                style={{ marginBottom: 30 }}
              >
                <span className="reveal">
                  We offer a strategic design that is customer
                </span>
                <br />
                <span className="reveal">
                  centric,with the goal of analysing, then
                </span>
                <br />
                <span className="reveal">
                  streamlining your overall goals and scope.
                </span>
                <br />
                <span className="reveal">
                  Business design provides how the business is
                </span>
                <br />
                <span className="reveal">
                  structured on holistic engagement and
                </span>
                <br />
                <span className="reveal">
                  relationship with everyone who supports the
                </span>
                <br />
                <span className="reveal">
                  business development process as well as
                </span>
                <br />
                <span className="reveal">customer value proposition.</span>
              </p>
              <h6
                onClick={() => handleToggle(3)}
                className="blueHover solutions_center_content_capabilities_h6"
              >
                <span className="reveal" style={{ marginRight: 25 }}>
                  Capabilities
                </span>
                <span className="reveal">
                  <i
                    className={`thinIcon fa fa-chevron-down fa-2xs ${
                      activeCollapsible === 3 ? "isCollapsed" : ""
                    }`}
                  ></i>
                </span>
              </h6>
              <div
                className={`w414 solutions_center_content_capabilities_description ${
                  activeCollapsible === 3 ? "isCollapsed" : ""
                }`}
                style={{
                  height: activeCollapsible === 3 ? sub.business.height : 0,
                }}
              >
                <Capabilities content={sub.business.content} />
              </div>
            </div>
            <div
              className="reveal solutions_center_content_img"
              style={{ background: "#D9D9D9" }}
            >
              <img
                className="solutions_center_content_img"
                src={businessImg}
                alt="Business Design"
              />
            </div>
          </section>
          <div
            className={`w1460 solutions_center_content_capabilities_description ${
              activeCollapsible === 3 ? "isCollapsed" : ""
            }`}
            style={{
              paddingTop: 81,
              height: activeCollapsible === 3 ? sub.business.height : 0,
            }}
          >
            <Capabilities content={sub.business.content} />
          </div>
        </div>
        {/* section-4 */}
        <div>
          <section className="solutions_center_content_section">
            <div
              className="w1460 reveal solutions_center_content_img"
              style={{ background: "#D9D9D9", marginRight: 78 }}
            >
              <img
                className="solutions_center_content_img"
                src={visualImg}
                alt="Visual Design Laptop"
              />
            </div>
            <div>
              <h5 className="reveal solutions_center_content_h5">
                Visual Design
              </h5>
              <p
                className="solutions_center_content_p"
                style={{ marginBottom: 30 }}
              >
                <span className="reveal">
                  Visual design is what sets the tone and voice for
                </span>
                <br />
                <span className="reveal">
                  your brand. Through our visualizations, your
                </span>
                <br />
                <span className="reveal">
                  product’s qualities are distinguished, while
                </span>
                <br />
                <span className="reveal">
                  translating your organizations unique
                </span>
                <br />
                <span className="reveal">
                  framework. This creates a personalized and
                </span>
                <br />
                <span className="reveal">
                  functional end product in which your users will
                </span>
                <br />
                <span className="reveal">find consistency.</span>
              </p>
              <h6
                onClick={() => handleToggle(4)}
                className="blueHover solutions_center_content_capabilities_h6"
              >
                <span className="reveal" style={{ marginRight: 25 }}>
                  Capabilities
                </span>
                <span className="reveal">
                  <i
                    className={`thinIcon fa fa-chevron-down fa-2xs ${
                      activeCollapsible === 4 ? "isCollapsed" : ""
                    }`}
                  ></i>
                </span>
              </h6>
              <div
                className={`w414 solutions_center_content_capabilities_description ${
                  activeCollapsible === 4 ? "isCollapsed" : ""
                }`}
                style={{
                  height: activeCollapsible === 4 ? sub.visual.mobileHeight : 0,
                }}
              >
                <Capabilities content={sub.visual.content} />
              </div>
            </div>
            <div
              className="w414 reveal solutions_center_content_img"
              style={{ background: "#D9D9D9", marginRight: 78 }}
            >
              <img
                className="solutions_center_content_img"
                src={visualImg}
                alt="Visual Design Laptop"
              />
            </div>
          </section>
          <div
            className={`w1460 solutions_center_content_capabilities_description ${
              activeCollapsible === 4 ? "isCollapsed" : ""
            }`}
            style={{
              paddingTop: 81,
              height: activeCollapsible === 4 ? sub.visual.height : 0,
            }}
          >
            <Capabilities content={sub.visual.content} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Design;

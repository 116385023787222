import { React, useState, useEffect, lazy } from "react";
import { Container } from "react-bootstrap";
import Hero from "./Hero";
import Partners from "./Partners";
import LogoSlider from "../../components/LogoSlider";

const Heading = lazy(() => import("./Heading"));
const What = lazy(() => import("./What"));
const Process = lazy(() => import("./Process"));
const Funding = lazy(() => import("./Funding"));
const Aboutus = lazy(() => import("./Aboutus"));
const Youtube = lazy(() => import("./Youtube"));

const Homepage = () => {
  const [showYoutube, setShowYoutube] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1100) {
        setShowYoutube(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowYoutube(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const toggleYoutube = () => {
    setShowYoutube(!showYoutube);
  };
  return (
    <div style={{ backgroundColor: "black", zIndex: "-99" }}>
      <Youtube toggleYoutube={toggleYoutube} showYoutube={showYoutube} />
      <Container className="homepage"></Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Hero />
        <LogoSlider />
        <What />
        <div
          style={{
            height: "425px",
            maxWidth: "425px",
            position: "absolute",
            left: "-10%",
            zIndex: 5,
            background: "#005EEB",
            borderRadius: "9999px",
            filter: "blur(200px)",
          }}
        ></div>
        <Funding />
        <Process />

        <Heading />
      </div>
      <Partners />
      <Aboutus />
    </div>
  );
};

export default Homepage;

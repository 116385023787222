import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "./LoanApplicationForm.css";

const FundingForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    businessName: "",
    email: "",
    phoneNumber: "",
    businessType: "",
    yearEstablished: "",
    annualRevenue: "",
    loanAmountRequested: "",
    loanPurpose: "",
    creditScore: "",
    loanAmountOptional: "",
    annualRevenueOptional: "",
    businessPlan: "",
    collateral: "",
  });

  const [errors, setErrors] = useState({});

  // Handle input change
  const handleInputChange = (e) => {
    const { id, value, name, type } = e.target;
    if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  // Validate form data
  const validateForm = () => {
    let formErrors = {};

    if (!formData.fullName) formErrors.fullName = "Full Name is required";
    if (!formData.businessName)
      formErrors.businessName = "Business Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!formData.phoneNumber)
      formErrors.phoneNumber = "Phone Number is required";
    if (!formData.businessType)
      formErrors.businessType = "Business Type is required";
    if (!formData.loanAmountRequested)
      formErrors.loanAmountRequested = "Loan Amount is required";
    if (!formData.loanPurpose)
      formErrors.loanPurpose = "Loan Purpose is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle valid form submission
      console.log("Form submitted", formData);
    } else {
      console.log("Form has errors");
    }
  };

  return (
    <>
      <div
        style={{
          maxWidth: "900px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "64px",
          marginTop: 96,
        }}
      >
        <h1
          style={{
            maxWidth: "520px",
            textAlign: "center",
            margin: "auto",
            lineHeight: "52px",
            paddingBottom: "36px",
          }}
        >
          Need funding for your project?
        </h1>
        <p
          style={{
            maxWidth: "380px",
            textAlign: "center",
            margin: "auto",
            fontWeight: "500",
          }}
        >
          Unlock the future of productivity with JX. Remember, this journey is
          just getting started.
        </p>
      </div>
      <div className="form-container">
        <h3 className="form-title">Personal Info</h3>
        <Form onSubmit={handleSubmit}>
          {/* Personal Info Section */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="fullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  isInvalid={!!errors.fullName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fullName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="businessName">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.businessName}
                  onChange={handleInputChange}
                  isInvalid={!!errors.businessName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.businessName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Input text"
                  value={formData.email}
                  onChange={handleInputChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Business Info Section */}
          <h3 className="form-title">Business Info</h3>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="businessType">
                <Form.Label>Business Type</Form.Label>
                <Form.Control
                  as="select"
                  className="custom-select"
                  value={formData.businessType}
                  onChange={handleInputChange}
                  isInvalid={!!errors.businessType}
                >
                  <option>Select</option>
                  <option>Small Business</option>
                  <option>Startup</option>
                  <option>Enterprise</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.businessType}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="yearEstablished">
                <Form.Label>Year Established</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.yearEstablished}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="annualRevenue">
                <Form.Label>Annual Revenue</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.annualRevenue}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="loanAmountRequested">
                <Form.Label>Loan Amount Requested</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.loanAmountRequested}
                  onChange={handleInputChange}
                  isInvalid={!!errors.loanAmountRequested}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.loanAmountRequested}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="loanPurpose" className="mb-5">
            <Form.Label>Purpose of the Loan</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Write text here..."
              value={formData.loanPurpose}
              onChange={handleInputChange}
              isInvalid={!!errors.loanPurpose}
            />
            <Form.Control.Feedback type="invalid">
              {errors.loanPurpose}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Additional Details (Optional) Section */}
          <h3 className="form-title">Additional Details (Optional)</h3>
          <Row className="mb-3">
            <Form.Group controlId="creditScore">
              <Form.Label>Credit Score</Form.Label>
              <Form.Control
                as="select"
                className="custom-select"
                value={formData.creditScore}
                onChange={handleInputChange}
              >
                <option>Select</option>
                <option>Excellent</option>
                <option>Good</option>
                <option>Fair</option>
                <option>Poor</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="mb-4">
            <Col md={6}>
              <Form.Group controlId="annualRevenueOptional">
                <Form.Label>Annual Revenue</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.annualRevenueOptional}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="loanAmountOptional">
                <Form.Label>Loan Amount Requested</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Input text"
                  value={formData.loanAmountOptional}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={6}>
              <Form.Group controlId="businessPlan">
                <Form.Label>Do you have a Business Plan?</Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    name="businessPlan"
                    value="Yes"
                    onChange={handleInputChange}
                    checked={formData.businessPlan === "Yes"}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    name="businessPlan"
                    value="No"
                    onChange={handleInputChange}
                    checked={formData.businessPlan === "No"}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="collateral">
                <Form.Label>Do you have Collateral?</Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    name="collateral"
                    value="Yes"
                    onChange={handleInputChange}
                    checked={formData.collateral === "Yes"}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    name="collateral"
                    value="No"
                    onChange={handleInputChange}
                    checked={formData.collateral === "No"}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          {/* Submit Button */}
          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit" className="submit-button">
              Apply Now!
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default FundingForm;

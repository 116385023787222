import { React, useState, useRef, useEffect } from "react";
import founder from "./../../img/About Us Page/jx_dan_perera_founder_meeting.png";
import VisibilitySensor from "react-visibility-sensor";

const Founder = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const founderRef = useRef(null);
  useEffect(() => {
    founderRef.current.classList.add("active");
  }, []);
  return (
    <section className="aboutus_founder about_w1460">
      <VisibilitySensor
        partialVisibility
        active={!viewPortEntered}
        onChange={(isVisible) => {
          if (isVisible) {
            setViewPortEntered(true);
          }
        }}
        delayedCall
      >
        {({ isVisible }) => (
          <>
            <h1
              className={`reveal aboutus_founder_h1 w414 ${
                isVisible ? "active" : ""
              }`}
            >
              Founder
            </h1>
            <div
              ref={founderRef}
              style={{ transitionDelay: "200ms" }}
              className={`about_founder_img reveal ${
                isVisible ? "active" : ""
              }`}
            >
              <div>
                <img className="grow" src={founder} alt="founder" />
              </div>
            </div>
          </>
        )}
      </VisibilitySensor>
      <div className="aboutus_founder_content">
        <VisibilitySensor
          partialVisibility
          active={!viewPortEntered}
          onChange={(isVisible) => {
            if (isVisible) {
              setViewPortEntered(true);
            }
          }}
          delayedCall
        >
          {({ isVisible }) => (
            <>
              <h1
                className={`reveal aboutus_founder_h1 w1460 ${
                  isVisible ? "active" : ""
                }`}
              >
                Founder
              </h1>
            </>
          )}
        </VisibilitySensor>

        <p className="about_founder_description">
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                Dananjaya began his career in 2003 as a freelance
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                web designer during his time at university. As a
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                passionate student, he never stopped learning,
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                always finding new ways to grow. This paved the
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                way for him to become a human-centered designer,
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                always keeping users at the forefront to create
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                products with depth and understanding.
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}></span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                He consistently designs products with the user
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                journey and pain points as the priority, and solves
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                business problems with digital transformation that is
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                flexible and prepared for an ever-changing digital
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                landscape. Creating a collaborative environment
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                with clients as partners, he keeps them engaged and
              </span>
            )}
          </VisibilitySensor>

          <br />
          <VisibilitySensor
            partialVisibility
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            {({ isVisible }) => (
              <span className={`reveal ${isVisible ? "active" : ""}`}>
                up to date every step of the way.
              </span>
            )}
          </VisibilitySensor>
        </p>
      </div>
    </section>
  );
};

export default Founder;

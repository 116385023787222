import { React, useEffect, useRef } from "react";
import * as DOMPurify from "dompurify";
import { LazyLoadImage } from "react-lazy-load-image-component";

import RightArrow from "./../../img/misc/jx_design_agency_next_button.svg";
import VisibilitySensor from "react-visibility-sensor";
import ClientsFooter from "../../components/ClientsFooter";

const Template = (props) => {
  const whoRef = useRef(null);
  useEffect(() => {
    const span = document.querySelectorAll(".casestudies_template_p > span");
    const whoSpan = whoRef.current.querySelectorAll("span");
    let count = 0;
    for (let i = 0; i < span.length; i++) {
      span[i].classList.add("reveal");
      count++;
    }
    for (let i = 0; i < whoSpan.length; i++) {
      whoSpan[i].style.transitionDelay = `${20 * i}ms`;
      setTimeout(() => {
        whoSpan[i].classList.add("active");
      }, 200);
    }
    console.log(count);
  }, []);
  return (
    <>
      <div className="flexContainer template" style={{ marginTop: 112 }}>
        <VisibilitySensor
          partialVisibility
          offset={{ bottom: 100 }}
          delayedCall
        >
          {({ isVisible }) => (
            <>
              <section className="casestudies_template_section_w1460">
                <div className={`reveal ${isVisible ? "active" : ""}`}>
                  <a
                    href="/case-studies"
                    className="blueHover casestudies_template_whatLink"
                  >
                    <div
                      style={{
                        background: `url(${RightArrow})`,
                        width: 30,
                        height: 30,
                        rotate: "180deg",
                        marginRight: 10,
                      }}
                    ></div>
                    <p>MORE WORK</p>
                  </a>
                </div>
              </section>
              <section className="casestudies_template_section_w1460">
                <h1
                  className={`reveal casestudies_template_h1 casestudies_template_title ${
                    isVisible ? "active" : ""
                  }`}
                >
                  {props.title}
                </h1>
              </section>
              <section className="casestudies_template_heading casestudies_template_section casestudies_template_section_w1460 flex">
                <div
                  className="casestudies_template_top"
                  style={{ marginRight: 257 }}
                >
                  <div
                    style={{ marginBottom: 16 }}
                    className="casestudies_template_head"
                  >
                    <h4
                      className={`reveal casestudies_template_h4 ${
                        isVisible ? "active" : ""
                      }`}
                      style={{ transitionDelay: "20ms" }}
                    >
                      Location
                    </h4>
                    <h6
                      className={`reveal casestudies_template_h6 ${
                        isVisible ? "active" : ""
                      }`}
                      style={{ transitionDelay: "40ms" }}
                    >
                      {props.location}
                    </h6>
                  </div>
                  <div className="casestudies_template_head">
                    <h4
                      className={`reveal casestudies_template_h4 ${
                        isVisible ? "active" : ""
                      }`}
                      style={{ transitionDelay: "60ms" }}
                    >
                      {props.label ? "Label" : "Project Type"}
                    </h4>

                    <h6
                      className={`reveal casestudies_template_h6 ${
                        isVisible ? "active" : ""
                      }`}
                      style={{ transitionDelay: "80ms" }}
                    >
                      {props.projectType.map((el, idx) => (
                        <div
                          className={`reveal  ${isVisible ? "active" : ""}`}
                          style={{ transitionDelay: "100ms" }}
                          key={idx}
                        >
                          <span style={{ marginBottom: 10 }}>{el}</span>
                          <br />
                        </div>
                      ))}
                    </h6>
                  </div>
                </div>
                <div>
                  <h2
                    style={{ transitionDelay: "80ms" }}
                    className={`reveal casestudies_template_h2 ${
                      isVisible ? "active" : ""
                    }`}
                  >
                    Who they are
                  </h2>
                  <p
                    ref={whoRef}
                    className="casestudies_template_p"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(props.whoTheyAre),
                    }}
                  ></p>
                </div>
              </section>
              <section
                style={{ transitionDelay: "120ms" }}
                className={`reveal casestudies_template_headingImg_container ${
                  isVisible ? "active" : ""
                }`}
              >
                <LazyLoadImage
                  className="casestudies_template_headingImg casestudies_template_img"
                  src={props.headingImg}
                  alt="Case Study Heading"
                />
              </section>
            </>
          )}
        </VisibilitySensor>
        <section>
          <h5
            className="reveal casestudies_template_h5 casestudies_template_serviceprovided"
            style={{ marginBottom: 48 }}
          >
            Services we provided
          </h5>
          <div className="casestudies_template_section flex">
            <div>
              <div
                className="casestudies_template_services"
                style={{ marginRight: 102 }}
              >
                {props.services.map((el, idx) => (
                  <>
                    <span
                      className="reveal casestudies_template_services_span"
                      key={idx}
                    >
                      {el}
                    </span>
                    <br />
                  </>
                ))}
              </div>
            </div>

            <div className="casestudies_template_overview">
              <h2 className="reveal casestudies_template_h2">Overview</h2>
              <p
                className="casestudies_template_p"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.overview),
                }}
              ></p>
            </div>
          </div>
        </section>
        {/* DELIVERABLES */}
        <section
          className="casestudies_template_section flex casestudies_template_deliverables"
          style={{ marginBottom: 87 }}
        >
          <div>
            <div className="reveal casestudies_template_deliverableImg_container">
              <LazyLoadImage
                className="casestudies_template_deliverableImg casestudies_template_img"
                src={props.deliverableImg}
                alt="Case Study deliverable"
              />
            </div>
          </div>

          <div>
            <h2 className="reveal casestudies_template_h2 casestudies_template_h2_deliverables">
              Deliverables
            </h2>
            <div>
              {props.deliverables.map((el, idx) => (
                <>
                  <div key={idx} style={{ marginBottom: 50 }}>
                    <h6
                      className="reveal casestudies_template_h6"
                      style={{ fontWeight: 700, fontSize: 24 }}
                    >
                      {el.title}
                    </h6>
                    <div>
                      {el.subtitles.map((sub, subIdx) => (
                        <>
                          <span
                            className="reveal casestudies_template_subtitles"
                            key={subIdx}
                          >
                            {sub}
                          </span>{" "}
                          <br />
                        </>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </section>
        {/* CHALLENGE */}
        <section
          className="casestudies_template_section_w1460"
          style={{ marginBottom: 102, marginLeft: 60 }}
        >
          <h5 className="reveal casestudies_template_h1 challenge">
            Challenge
          </h5>
          <div>
            <p
              className="casestudies_template_p"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.challenge),
              }}
              style={{ marginBottom: 87 }}
            ></p>
          </div>
          <div className="reveal casestudies_template_challengeImg_container">
            <LazyLoadImage
              className="casestudies_template_challengeImg casestudies_template_img"
              src={props.challengeImg}
              alt="Case Study Challenge"
            />
          </div>
        </section>
      </div>
      <ClientsFooter />
    </>
  );
};

export default Template;

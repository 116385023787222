import React from "react";
import { Container } from "react-bootstrap";

import HVALogo from "./../img/Clients Logo Light/hva_ceylon_coconut_milk_organic_wholesale_foodandbeverage_vector_logo_light.svg";
import CargoLogo from "./../img/Clients Logo Light/cargo_login_frieght_fowarding_logistics_supplychain_vector_logo_light.svg";
import ANZLogo from "./../img/Clients Logo Light/anz _banking_vector_logo_light.svg";
import AusPostLogo from "./../img/Clients Logo Light/australiapost_mail_post_vector_logo_light.svg";
import YalumbaLogo from "./../img/Clients Logo Light/yalumba_winery_foodandbeverage_vector_logo_light.svg";
import BupaLogo from "./../img/Clients Logo Light/bupa_health_insurance_vector_logo_light.svg";
import KojoLogo from "./../img/Clients Logo Light/kojo_digital_vector_logo_light.svg";
import RMITLogo from "./../img/Clients Logo Light/royal_melbourne_institute_technology_vector_logo_light.svg";
import BOALogo from "./../img/Clients Logo Light/bankofamerica_banking_vector_logo_light.svg";
import StandCharLogo from "./../img/Clients Logo Light/standard_chartered_banking_vector_logo_light.svg";
import AGLLogo from "./../img/Clients Logo Light/agl_energy_vector_logo_light.svg";
import NABLogo from "./../img/Clients Logo Light/national_australia_bank_banking_vector_logo_light.svg";
import phenixLogo from "./../img/Clients Logo Light/phenix_light_logo.svg";
import hostLogo from "./../img/Clients Logo Light/host.svg";
import lasendaLogo from "./../img/Clients Logo Light/lasenda.svg";
import virtikLogo from "./../img/Clients Logo Light/virtik.svg";

const ClientsFooter = () => {
  const logos = [
    {
      alt: "Phenix",
      width: 176,
      height: 60,
      margin: "0 40px 60px 0",
      img: phenixLogo,
    },
    {
      alt: "LaSenda",
      width: 170,
      height: 26,
      margin: "16px 0px 46px 0px",
      img: lasendaLogo,
    },
    {
      alt: "Host",
      width: 92,
      height: 29,
      margin: "10px 26px 46px ",
      img: hostLogo,
    },
    {
      alt: "Virtik",
      width: 170,
      height: 27,
      margin: "15px 86px 46px 0px",
      img: virtikLogo,
    },
    {
      alt: "Bank of America",
      width: 153,
      height: 62.86,
      margin: "0 86px 0 0",
      img: BOALogo,
    },
    {
      alt: "HVA Ceylon",
      width: 88,
      height: 52.13,
      margin: "0 82px 90px 0",
      img: HVALogo,
    },
    {
      alt: "Cargo Login",
      width: 124,
      height: 51,
      margin: "0px 36px 90px 15px",
      img: CargoLogo,
    },
    {
      alt: "ANZ",
      width: 147.93,
      height: 47,
      margin: "0 89px 84px 0",
      marginBottom: 121,
      marginRight: 70,
      img: ANZLogo,
    },
    {
      alt: "Australia Post",
      width: 144.07,
      height: 51.2,
      margin: "0 78px 79px 0",
      img: AusPostLogo,
    },
    {
      alt: "Yalumba",
      width: 98.55,
      height: 57.07,
      margin: "0 38px 40px 38px ",
      marginBottom: 85,
      marginRight: 78,
      img: YalumbaLogo,
    },

    // ===
    {
      alt: "AGL",
      width: 73.97,
      height: 70.46,
      margin: "0 36px 70px 36px ",
      img: AGLLogo,
    },
    {
      alt: "Kojo",
      width: 82.51,
      height: 54,
      margin: "0 87px 0 0",
      img: KojoLogo,
    },
    {
      alt: "Bupa",
      width: 67,
      height: 67,
      margin: "0 36px 70px 36px",
      img: BupaLogo,
    },

    {
      alt: "Standard Chartered",
      width: 163.61,
      height: 64,
      margin: "0 81px 0 0",
      img: StandCharLogo,
    },
    {
      alt: "RMIT University",
      width: 128.54,
      height: 44.56,
      margin: "15px 87px 0 0",
      img: RMITLogo,
    },
    {
      alt: "nab",
      width: 125.08,
      height: 53,
      margin: "10px 20px 70px 20px",
      img: NABLogo,
    },
  ];
  const logosMobile = [
    {
      alt: "HVA Ceylon",
      width: 74,
      height: 45,
      img: HVALogo,
    },
    {
      alt: "Cargo Login",
      width: 109,
      height: 45,
      img: CargoLogo,
    },
    {
      alt: "ANZ",
      width: 116,
      height: 37,
      marginBottom: 121,
      marginRight: 70,
      img: ANZLogo,
    },
    {
      alt: "Australia Post",
      width: 114,
      height: 40,
      img: AusPostLogo,
    },
    {
      alt: "Yalumba",
      width: 88,
      height: 51,
      img: YalumbaLogo,
    },
    {
      alt: "Bank of America",
      width: 107,
      height: 44,
      img: BOALogo,
    },
    // ===
    {
      alt: "AGL",
      width: 72,
      height: "auto",
      img: AGLLogo,
    },
    {
      alt: "Kojo",
      width: 77,
      height: "auto",
      img: KojoLogo,
    },
    {
      alt: "Bupa",
      width: 50,
      height: 50,
      img: BupaLogo,
    },
    {
      alt: "Standard Chartered",
      width: 115,
      height: 45,
      img: StandCharLogo,
    },
    {
      alt: "RMIT University",
      width: 109,
      height: 37,
      img: RMITLogo,
    },
  ];
  return (
    <div className="clientFooter">
      <Container className="w1460">
        <h5 className="clientFooter_h5 " style={{ paddingTop: "96px" }}>
          Our Partners & Clients
        </h5>
        <div className="clientFooter_logoContainer">
          {logos.map((el, idx) => (
            <img
              key={idx}
              src={el.img}
              width={el.width}
              height={el.height}
              style={{ margin: el.margin }}
              alt={el.alt}
            />
          ))}
        </div>
      </Container>
      <Container className="w414">
        <h5 className="clientFooter_h5">
          <span>Our Partners</span> <br />
          <span>& Clients</span>
        </h5>
        <div className="clientFooter_logoContainer">
          {logosMobile.map((el, idx) => (
            <img
              key={idx}
              src={el.img}
              width={el.width}
              height={el.height}
              style={{ margin: el.margin }}
              alt={el.alt}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ClientsFooter;

export const COUNTRIES_DATA = [
  {
    country: "USA",
    latitude: 34.0522,
    longitude: -118.2437,
    name: "Los Angeles",
  },
  {
    country: "AU",
    latitude: -37.8136,
    longitude: 144.9631,
    name: "Melbourne",
  },
  {
    country: "SG",
    latitude: 1.3521,
    longitude: 103.8198,
    name: "Singapore",
  },
  {
    country: "LK",
    latitude: 6.9271,
    longitude: 79.8612,
    name: "Colombo",
  },
  {
    country: "AE",
    latitude: 25.276987,
    longitude: 55.296249,
    name: "Dubai",
  },
  {
    country: "UK",
    latitude: 51.5074,
    longitude: -0.1278,
    name: "London",
  },

  {
    country: "USA",
    latitude: 25.7617,
    longitude: -80.1918,
    name: "Miami",
  },
];

import { React, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import customAiApplications from "../../img/Solutions Page/Ai/applications.png";
import computerVision from "../../img/Solutions Page/Ai/vision.png";
import generativeAiServices from "../../img/Solutions Page/Ai/services.png";
import aiDdiscovery from "../../img/Solutions Page/Ai/discovery.png";

const Development = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <>
      {/* section-1 */}
      <VisibilitySensor
        partialVisibility
        offset={{ bottom: 100 }}
        active={!viewPortEntered}
        onChange={(isVisible) => {
          if (isVisible) {
            setViewPortEntered(true);
          }
        }}
        delayedCall
      >
        {({ isVisible }) => (
          <section
            className="solutions_center_content_section"
            style={{ marginBottom: 108 }}
          >
            <div style={{ marginRight: 85.5 }}>
              <h5
                className={`reveal solutions_center_content_h5 ${
                  isVisible ? "active" : ""
                }`}
              >
                Custom AI applications
              </h5>
              <p className="solutions_center_content_p">
                <span
                  style={{ transitionDelay: "20ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  Whether you want to analyze medical data
                </span>
                <span
                  style={{ transitionDelay: "60ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  prepare a recommendation list for users,
                </span>
                <span
                  style={{ transitionDelay: "80ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  predict prices for sales and marketing
                </span>
                <span
                  style={{ transitionDelay: "100ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  strategy, and reach any other of your
                </span>
                <span
                  style={{ transitionDelay: "120ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  business goals, our specialists will analyze
                </span>
                <span
                  style={{ transitionDelay: "140ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  your requirements and create a perfect
                </span>
                <span
                  style={{ transitionDelay: "160ms" }}
                  className={`reveal ${isVisible ? "active" : ""}`}
                >
                  custom solution for them.
                </span>
              </p>
            </div>
            <div
              style={{ transitionDelay: "40ms", background: "#D9D9D9" }}
              className={`reveal solutions_center_content_img ${
                isVisible ? "active" : ""
              }`}
            >
              <img
                className="solutions_center_content_img"
                src={customAiApplications}
                alt="Custom AI applications"
              />
            </div>
          </section>
        )}
      </VisibilitySensor>
      {/* section-2 */}
      <section
        style={{ marginBottom: 108 }}
        className="solutions_center_content_section"
      >
        <div
          className="reveal solutions_center_content_img"
          style={{ background: "#D9D9D9", marginRight: 78 }}
        >
          <img
            className="solutions_center_content_img"
            src={computerVision}
            alt="Computer vision"
          />
        </div>
        <div>
          <h5 className="reveal solutions_center_content_h5">
            Computer vision
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">Computer vision works almost like a </span>
            <span className="reveal">human eye. It enables a machine to</span>
            <span className="reveal">identify, process, recognize, and</span>
            <span className="reveal">
              understand all types of objects in images
            </span>
            <span className="reveal">
              and videos. Yellow’s AI software developers
            </span>
            <span className="reveal">
              will help you integrate computer vision{" "}
            </span>
            <span className="reveal">
              into your application, website, or platform
            </span>
          </p>
        </div>
      </section>
      {/* section-3 */}
      <section
        style={{ marginBottom: 149 }}
        className="solutions_center_content_section"
      >
        <div style={{ marginRight: 104.5 }}>
          <h5 className="reveal solutions_center_content_h5">
            Generative AI services
          </h5>
          <p className="solutions_center_content_p">
            <span className="reveal">
              Transform your business with the power of
            </span>
            <span className="reveal">
              generative AI, fueled by GPT and image
            </span>
            <span className="reveal">generation with Midjourney, Stable </span>
            <span className="reveal">
              Diffusion or DALL·E 2. Generate stunning
            </span>
            <span className="reveal">visuals, personalized content, and</span>
            <span className="reveal">automate complex tasks.</span>
          </p>
        </div>
        <div
          className="reveal solutions_center_content_img"
          style={{ background: "#D9D9D9" }}
        >
          <img
            className="solutions_center_content_img"
            src={generativeAiServices}
            alt="Generative AI services"
          />
        </div>
      </section>
      {/* section-4 */}
      <section className="solutions_center_content_section">
        <div
          className="reveal solutions_center_content_img"
          style={{ background: "#D9D9D9", marginRight: 78 }}
        >
          <img
            className="solutions_center_content_img"
            src={aiDdiscovery}
            alt="AI discovery"
          />
        </div>
        <div>
          <h5 className="reveal solutions_center_content_h5">AI discovery</h5>
          <p className="solutions_center_content_p">
            <span className="reveal">
              A dedicated service to create AI-driven
            </span>
            <span className="reveal">
              solutions. After defining and refining your{" "}
            </span>
            <span className="reveal">
              initial product idea, we’ll build machine
            </span>
            <span className="reveal">
              learning prototypes to analyze feasibility.
            </span>
          </p>
        </div>
      </section>
    </>
  );
};

export default Development;

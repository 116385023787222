import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { COUNTRIES_DATA } from "./data/countries_data";
import HEX_DATA from "./data/countries_hex_data.json";
import Globe from "react-globe.gl";
import earthNight from "../../img/globe/earth-night.jpg";
//import nightSky from "../../img/globe/night-sky.png";

export default function CustomGlobe() {
  const globeEl = useRef();
  const [countryIndex, setCountryIndex] = useState(0); // Start with the first country
  const [selectedCountry, setSelectedCountry] = useState({
    lat: COUNTRIES_DATA[0].latitude,
    lng: COUNTRIES_DATA[0].longitude,
    label: COUNTRIES_DATA[0].name,
  });
  const [hex, setHex] = useState({ features: [] });

  useEffect(() => {
    setHex(HEX_DATA);
  }, []);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      (async () => {
        const nextIndex = (countryIndex + 1) % COUNTRIES_DATA.length; // Loop through the countries
        const country = COUNTRIES_DATA[nextIndex];
        setSelectedCountry({
          lat: country.latitude,
          lng: country.longitude,
          label: country.name,
        });
        setCountryIndex(nextIndex); // Update the index to the next country
      })();
    }, 3000); // Every 3 seconds

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [countryIndex]); // Dependency on countryIndex to update the interval

  useEffect(() => {
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 0.2;

    const MAP_CENTER = { lat: 0, lng: 0, altitude: 1.5 };
    globeEl.current.pointOfView(MAP_CENTER, 0);
  }, [globeEl]);

  useEffect(() => {
    const countryLocation = {
      lat: selectedCountry.lat,
      lng: selectedCountry.lng,
      altitude: 1.5,
    };

    globeEl.current.pointOfView(countryLocation, 0);
  }, [selectedCountry]);

  return (
    <Globe
      ref={globeEl}
      globeImageUrl={earthNight}
      showGlobe={true}
      backgroundColor="rgba(0,0,0,0)"
      labelsData={[selectedCountry]}
      labelText={"label"}
      labelSize={1.8}
      labelColor={useCallback(() => "black", [])}
      labelDotRadius={0}
      labelAltitude={0.5}
      hexPolygonsData={hex.features}
      hexPolygonResolution={3} // Values higher than 3 make it buggy
      hexPolygonMargin={0.62}
      hexPolygonColor={() => "rgba(0,0,0, 1)"}
      height={400}
      width={400}
    />
  );
}

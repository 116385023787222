import { React, useEffect } from "react";
import * as DOMPurify from "dompurify";

const Capabilities = (props) => {
  useEffect(() => {
    const span = document.querySelectorAll(".child > p > span:not(.reveal)");
    for (let i = 0; i < span.length; i++) {
      span[i].classList.add("dropReveal");
    }
  }, []);

  return (
    <>
      {props.content.map((el, idx) => (
        <div className="child" key={idx}>
          <h6 className="dropReveal">{el.title}</h6>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(el.description),
            }}
          ></p>
        </div>
      ))}
    </>
  );
};

export default Capabilities;

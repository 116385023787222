import React from "react";
import ReactDOM from "react-dom/client";
import "./bootstrap.min.css";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Homepage from "./views/homepage/Homepage";
import ErrorPage from "./ErrorPage";
import Aboutus from "./views/about-us/About";
import CaseStudies from "./views/case-studies/CaseStudies";
import Startup from "./views/startup-catalyst/Startup";
import Faq from "./views/faq/Faq";
import Contact from "./views/Contact-us/Contact";
import Solutions from "./views/solutions/Solutions";
import Template from "./views/case-studies/Template";
import FundingForm from "./views/fundingform/fundingform";

// Bupa
import bupaHeadImg from "./img/Case Study Individual Pages/Bupa/jx_bupa_mobile_uxui_design_productdesign.svg";
import bupaDeliverablesImg from "./img/Case Study Individual Pages/Bupa/jx_bupa_mobile_uxui_design_product.svg";
import bupaChallengeImg from "./img/Case Study Individual Pages/Bupa/jx_bupa_mobile_uxui_design_serviceblueprint.svg";

// AusPost
import AusPostHeadImg from "./img/Case Study Individual Pages/AusPost/jx_australiapost_journeymap_design_userflow.svg";
import AusPostDeliverablesImg from "./img/Case Study Individual Pages/AusPost/jx_australiapost_journeymap_design_persona.svg";
import AusPostChallengeImg from "./img/Case Study Individual Pages/AusPost/jx_australiapost_journeymap_design_digital.svg";

// Yalumba
import YalumbaHeadImg from "./img/Case Study Individual Pages/Yalumba/jx_yalumba_desktop_uxui_service_design.svg";
import YalumbaDeliverablesImg from "./img/Case Study Individual Pages/Yalumba/jx_yalumba_mobile_uxui_service_design_productdesign.svg";
import YalumbaChallengeImg from "./img/Case Study Individual Pages/Yalumba/jx_yalumba_mobile_uxui_service_design_product.svg";

// ANZ
import ANZHeadImg from "./img/Case Study Individual Pages/ANZ/jx_anz_desktop_uxui_digital_service_design_system.svg";
import ANZDeliverablesImg from "./img/Case Study Individual Pages/ANZ/jx_anz_desktop_uxui_digital_service_design_framework.svg";
import ANZChallengeImg from "./img/Case Study Individual Pages/ANZ/jx_anz_desktop_uxui_digital_service_design_information_archutecture.svg";

// AGL
import AGLHeadImg from "./img/Case Study Individual Pages/AGL/jx_agl_desktop_mobile_uxui_service_design.svg";
import AGLDeliverablesImg from "./img/Case Study Individual Pages/AGL/jx_agl_mobile_uxui_service_design.svg";
import AGLChallengeImg from "./img/Case Study Individual Pages/AGL/jx_agl_serviceblueprint_uxui_service_design.svg";

// Parachute
import ParachuteHeadImg from "./img/Case Study Individual Pages/Parachute/jx_parachuute_mobile_uxui_design_productdesign.svg";
import ParachuteDeliverablesImg from "./img/Case Study Individual Pages/Parachute/jx_parachuute_mobile_uxui_design_product.svg";
import ParachuteChallengeImg from "./img/Case Study Individual Pages/Parachute/jx_parachuute_mobile_uxui_design_productdesign_ui.svg";

// Miracle Palm
import MiraclePalmHeadImg from "./img/Case Study Individual Pages/Miracle Palm/jx_hva_packaging_design.svg";
import MiraclePalmDeliverablesImg from "./img/Case Study Individual Pages/Miracle Palm/jx_hva_packaging_design_branding.svg";
import MiraclePalmChallengeImg from "./img/Case Study Individual Pages/Miracle Palm/jx_hva_packaging_design_product.svg";

// Lacrosse
import LacrosseHeadImg from "./img/Case Study Individual Pages/Lacrosse/jx_lacrosse_digital_sinage_design_tram.svg";
import LacrosseDeliverablesImg from "./img/Case Study Individual Pages/Lacrosse/jx_lacrosse_digital_sinage_design_tram_tracker.svg";
import LacrosseChallengeImg from "./img/Case Study Individual Pages/Lacrosse/jx_lacrosse_digital_sinage_design_tram_tracker_experience_1445389779.jpg";

// Loonum/SURYA
import loonumHeadImg from "./img/Case Study Individual Pages/Loonum/loonum_head.svg";
import loonumDeliverablesImg from "./img/Case Study Individual Pages/Loonum/loonum_deliverables.svg";
import loonumChallengeImg from "./img/Case Study Individual Pages/Loonum/loonum_challenge.svg";

// Phenix
import phenixHeadImg from "./img/Case Study Individual Pages/Phenix/phenix_head.svg";
import phenixDeliverablesImg from "./img/Case Study Individual Pages/Phenix/phenix_deliverables.svg";
import phenixChallengeImg from "./img/Case Study Individual Pages/Phenix/Phenix_challenge.svg";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      // work
      {
        path: "case-studies",
        element: <CaseStudies />,
      },
      // BUPA
      {
        path: "case-studies/bupa",
        element: (
          <Template
            headingImg={bupaHeadImg}
            challengeImg={bupaChallengeImg}
            deliverableImg={bupaDeliverablesImg}
            title="Bupa"
            location="Melbourne Australia"
            projectType={["DIGITAL SERVICE DESIGN"]}
            whoTheyAre="
            <span>Bupa is an international health insurance </span> <br />
            <span>and healthcare group with over 38 million </span> <br />
            <span>customers worldwide.They have grown to </span> <br />
            <span>be a global organization with over 38 million </span> <br />
            <span>customers worldwide. </span> <br />
            "
            services={[
              "User Research",
              "Design Thinking",
              "Lean Canvas Ideation",
              "UX & Interaction Design",
              "User Testing",
              "UI Design",
            ]}
            overview="
            <span>Bupa experienced losing the premium </span> <br />
            <span>customers and the task presented to us </span> <br />
            <span>was to create and understand the problem </span> <br />
            <span>statement:”How might we retain Bupa’s </span> <br />
            <span>premium customers by offering an added </span> <br />
            <span>value to their experience so they feel valued </span> <br />
            <span>by their service provider” We revisted their </span> <br />
            <span>customer journey and digital engagement </span> <br />
            <span>artifacts to deliver better services as well as </span> <br />
            <span>better user experience. </span> <br />
            "
            deliverables={[
              {
                title: "UX/UI",
                subtitles: [
                  "Wireframes",
                  "Usability Testing",
                  "UI for Mobile App ",
                ],
              },
              {
                title: "Service Design",
                subtitles: [
                  "Customer Journey Mapping",
                  "Prototyping",
                  "Service Blueprint",
                ],
              },
            ]}
            challenge="<span>Our challenge was establishing an engaging relationship between baby boomers</span><br />
            <span>experiencing changes in their lifestyle and their health, well-being, and health insurance</span><br />
            <span>so that they get value out of the service.</span>"
          />
        ),
      },
      // SURYA
      {
        path: "case-studies/SURYA",
        element: (
          <Template
            headingImg={loonumHeadImg}
            challengeImg={loonumChallengeImg}
            deliverableImg={loonumDeliverablesImg}
            title="SURYA"
            location="Los Angeles"
            projectType={["Learning Management System (LMS)"]}
            whoTheyAre="
            <span>SURYA is a global tech leader, pioneering </span> <br />
            <span>advancements in AI, cloud computing, and </span> <br />
            <span>digital solutions to empower businesses with </span> <br />
            <span>scalable, secure technologies that drive  </span> <br />
            <span>efficiency, innovation, and a connected future. </span>
            "
            services={[
              "Competitive Analysis",
              "UX/UI Design",
              "Frontend Development",
              "Backend Development",
              "Database Architecture",
              "Integrations",
            ]}
            overview="
            <span>The Learning Management Software (LMS) </span> <br />
            <span>project enhances training by delivering,  </span> <br />
            <span>tracking, and assessing content with features </span> <br />
            <span>like role-based course assignments, </span> <br />
            <span>interactive assessments, progress tracking, </span> <br />
            <span>and analytics. With a user-friendly interface </span> <br />
            <span>and multi-format content support, the LMS  </span> <br />
            <span>promotes continuous learning and skill- </span> <br />
            <span>building, empowering organizations with </span> <br />
            <span>insights into learner engagement and </span> <br />
            <span>performance. </span>
            "
            deliverables={[
              {
                title: "UX/UI",
                subtitles: [
                  "Wireframes",
                  "Usability Testing",
                  "UI for Mobile App ",
                ],
              },
              {
                title: "Development",
                subtitles: [
                  "Web Application",
                  "Mobile Application",
                  "Source Code",
                  "Test Cases",
                  "User Manual",
                ],
              },
            ]}
            challenge="<span>Developing the Learning Management Software (LMS) came with significant challenges, especially in </span><br />
            <span>simplifying features compared to other complex platforms on the market. Balancing a user-friendly </span><br />
            <span>interface with powerful functionality required careful design to ensure the system remained intuitive </span><br />
            <span>without sacrificing depth. </span><br/>
<br/>
            <span>Integrating diverse content formats (PDFs, videos, assessments) while maintaining compatibility across </span><br />
            <span>devices demanded rigorous testing. Additionally, building robust analytics and reporting features to </span><br />
            <span>meet varied client needs added complexity, requiring close collaboration with stakeholders to deliver </span><br />
            <span>comprehensive, meaningful insights.</span>"
          />
        ),
      }, // Phenix
      {
        path: "case-studies/Phenix",
        element: (
          <Template
            headingImg={phenixHeadImg}
            challengeImg={phenixChallengeImg}
            deliverableImg={phenixDeliverablesImg}
            title="Phenix"
            location="Riverside, California"
            projectType={["Distributor web application"]}
            whoTheyAre="
            <span>Phenix Technology, Inc. is an industry </span> <br />
            <span>recognized manufacturer of high quality safety </span> <br />
            <span>products built to NFPA and OSHA industry </span> <br />
            <span>standards with a quality management system </span> <br />
            <span>ISO 9001:2015 certified. Phenix products are </span> <br />
            <span>used by firefighters and first responders </span> <br />
            <span>worldwide.</span> <br />
            "
            services={[
              "Design Thinking",
              "User Research",
              "UX/UI Design",
              "User Testing",
              "Frontend Development",
              "Backend Development",
              "Database Architecture",
              "Integrations",
            ]}
            overview="
              <span>The Distributor Web Application streamlines </span> <br />
              <span>operations by centralizing inventory tracking, </span> <br />
              <span>order and purchase order management, </span> <br />
              <span>automated invoicing, and customer accounts.</span> <br />
<br/>
              <span>Seamlessly integrating with ERP systems, it </span> <br />
              <span>reduces manual tasks and ensures data </span> <br />
              <span>accuracy. With analytics and reporting, </span> <br />
              <span>distributors can monitor metrics, forecast </span> <br />
              <span>demand, and enhance efficiency—ultimately </span> <br />
              <span>improving customer service and supporting </span> <br />
              <span>growth. </span> <br />
            "
            deliverables={[
              {
                title: "UX/UI",
                subtitles: [
                  "Wireframes",
                  "Usability Testing",
                  "UI for Mobile App ",
                ],
              },
              {
                title: "Development",
                subtitles: [
                  "Web Application",
                  "Mobile Application",
                  "Source Code",
                  "Test Cases",
                  "User Manual",
                ],
              },
            ]}
            challenge="
            <span>Building the Distributor Web Application presented several challenges, particularly in ensuring </span><br />
            <span>seamless integration with diverse ERP systems, which required extensive testing and customization to </span><br />
            <span>maintain data accuracy across platforms. </span><br/>
<br/>
            <span>Balancing a comprehensive feature set with an intuitive, user-friendly design was also demanding, as </span><br />
            <span>we needed to simplify complex processes like purchase order management and real-time inventory </span><br />
            <span>tracking. </span>
            "
          />
        ),
      },
      // australia post
      {
        path: "case-studies/australia post",
        element: (
          <Template
            headingImg={AusPostHeadImg}
            challengeImg={AusPostChallengeImg}
            deliverableImg={AusPostDeliverablesImg}
            title="AUSTRALIA POST"
            location="Melbourne Australia"
            projectType={["CX & DIGITAL SERVICE DESIGN"]}
            whoTheyAre="
            <span>Australia Post, is the government business </span><br/>
            <span>enterprise that provides postal services in Australia. </span><br/>
            <span>They provide services to many Australians, and </span><br/>
            <span>regularly visit over 12.4 million delivery points across </span><br/>
            <span>the country. </span><br/>
            "
            services={[
              "Stakeholder Management",
              "Customer Segment Research",
              "Synthesis",
              "Affinity Mapping & Clustering",
              "LUMA Methods Implementation",
            ]}
            overview="
            <span>The largest logistical network in Australia </span><br/>
            <span>wanted to penetrate the internet service </span><br/>
            <span>market, utilizing their retail post office </span><br/>
            <span>footprint.  We were tasked with designing </span><br/>
            <span>end to end customer experience as well as </span><br/>
            <span>digital service offering to launch the product </span><br/>
            <span>into the Australian public. </span><br/>
            "
            deliverables={[
              {
                title: "CX Design",
                subtitles: [
                  "Physical Journey Mapping",
                  "Digital Journey Mapping",
                  "Value Proposition Canvas",
                ],
              },
              {
                title: "Visual Design",
                subtitles: ["Journey Illustrations"],
              },
            ]}
            challenge="
            <span>As we discovered that there was an opportunity for AUSPOST in the NBN market churn, </span> <br/>
            <span>we challenged the VDF framework during our analysis on how a trusted & local courier </span> <br/>
            <span>company could maximise its revenue generation through utilising its physical presence.</span>"
          />
        ),
      },
      // Yalumba
      {
        path: "case-studies/Yalumba",
        element: (
          <Template
            headingImg={YalumbaHeadImg}
            challengeImg={YalumbaChallengeImg}
            deliverableImg={YalumbaDeliverablesImg}
            title="Yalumba"
            location="Barossa Valley Australia"
            projectType={["CX & Digital Service Design"]}
            whoTheyAre="
            <span>Yalumba is an Australian winery located near the</span><br/>
            <span>town of Angaston, South Australia in the Barossa</span><br/> 
            <span>Valley wine region. In 1849 Smith and his son</span><br/> 
            <span>Sidney planted Yalumba’s first vineyards, beginning</span><br/> 
            <span>the Yalumba dynasty. Today Yalumba is Australia’s</span><br/> 
            <span>oldest family-owned winery.</span>
            "
            services={[
              "User Research",
              "User Flow",
              "Competitor Analysis",
              "Design Thinking/ Ideation",
            ]}
            overview="
            <span>We were tasked with transforming the digital </span><br/> 
            <span>service engagement. The project was </span><br/> 
            <span>approached with a Service Design lens then </span><br/> 
            <span>moved to UX research, ideation and </span><br/> 
            <span>wireframes. Collaboration and Co-creation </span><br/> 
            <span>was a big part of the client and agency </span><br/> 
            <span>workshops align to deliver great outcomes</span><br/>             
            "
            deliverables={[
              {
                title: "UX/UI",
                subtitles: ["Info. Architecture", "Low Fidelity Wireframes"],
              },
              {
                title: "Service Design",
                subtitles: [
                  "Current/Future State Scope",
                  "HMW statement",
                  "Customer Value Proposition",
                  "Persona(s)",
                  "Customer Journey Map",
                ],
              },
            ]}
            challenge="
            <span>The current state had low brand perception and digital engagement. Our challenge was</span><br/>
            <span>to increase premiumisation via digital blueprint with an exceptional user centric approach.</span>"
          />
        ),
      },
      // ANZ
      {
        path: "case-studies/ANZ",
        element: (
          <Template
            headingImg={ANZHeadImg}
            challengeImg={ANZChallengeImg}
            deliverableImg={ANZDeliverablesImg}
            title="ANZ"
            location="Melbourne Australia"
            projectType={[
              "UX Research & UI Design",
              "Digital Experience Design ",
            ]}
            whoTheyAre="
            <span>The Australia and New Zealand Banking Group </span> <br/>
            <span>Limited is an Australian multinational banking </span> <br/>
            <span>and financial services company headquartered in </span> <br/>
            <span>Melbourne, Victoria. It is Australia’s second-largest </span> <br/>
            <span>bank by assets and fourth-largest bank by </span> <br/>
            <span>market capitalization.</span>
            "
            services={[
              "Stakeholder Management",
              "Team Coaching",
              "Current State Discovery",
              "User Research",
              "Design Thinking, Ideation",
              "Remote User Testing",
              "Stakeholder Management",
              "Digital Design Strategy",
              "Design Thinking Workshops",
            ]}
            overview="
            <span>ANZ has one of the most sophisticated systems </span><br/>
            <span>that has been created to support every single </span><br/>
            <span>transaction that goes through the bank. We </span><br/>
            <span>focused on multiple user groups which have </span><br/>
            <span>various hierarchy and access levels to operate </span><br/>
            <span>on executing held transactions due to </span><br/>
            <span>fraudulent incident reports.</span><br/>
            "
            deliverables={[
              {
                title: "UX/UI Design",
                subtitles: [
                  "Persona(s)",
                  "Info. Architecture",
                  "Lo Fid. Wireframes",
                  "UI Designs",
                  "UX Framework",
                ],
              },
            ]}
            challenge="
<span>The team was not supported by an UX Designer after the initial UI was designed. Before we</span> <br/>
<span>stepped in, the design decisions were made by non designer professionals, so our priority was</span> <br/>
<span>to discover the current pain points of the user and improve the User Experience and User</span> <br/> 
<span>Interface. Our challenge was reversing and iterating non design related UI development and</span> <br/> 
<span>changing the pace of the team. An opportunity materialized in being able to coach them to</span> <br/>
<span>work collaboratively during the design process.</span> "
          />
        ),
      },
      // AGL
      {
        path: "case-studies/AGL",
        element: (
          <Template
            headingImg={AGLHeadImg}
            challengeImg={AGLChallengeImg}
            deliverableImg={AGLDeliverablesImg}
            title="AGL"
            location="Melbourne Australia"
            projectType={["UX Research & UI Design", "Digital Service Design"]}
            whoTheyAre="
<span>AGL Energy is an Australian electricity and gas</span><br/> 
<span>company for residential and commercial use.</span><br/>   
<span>AGL is Australia’s largest electricity generator,</span><br/>  
<span>serving millions.</span>  
            "
            services={[
              "User Research",
              "Workshop Facilitation",
              "Content Strategy",
              "Heuristics Evaluation",
              "UI Design",
              "Stakeholder Management",
              "Optimization Strategy",
              "Customer Segment",
              "Research Synthesis",
            ]}
            overview="
            <span>We were tasked with redesigning the AGL </span><br/> 
            <span>gas and electricity plans page, addressing UX </span><br/> 
            <span>issues, introducing transparency, aiding in </span><br/> 
            <span>users comprehension and reducing friction in </span><br/> 
            <span>decision making when users consider a gas </span><br/> 
            <span>and/or electricity plan with AGL.</span><br/><br/> 

            <span>After AGL’s digital transformation, we </span><br/> 
            <span>revisited the Sales Funnel performance. Part </span><br/> 
            <span>of that process was to design a Journey Map </span><br/> 
            <span>where stakeholders can visualize the current </span><br/> 
            <span>state and monitor performances. </span><br/> 
            "
            deliverables={[
              {
                title: "UX/UI",
                subtitles: [
                  "Wireframes",
                  "Usability Testing",
                  "UI for Mobile App ",
                ],
              },
              {
                title: "Digital Service Design",
                subtitles: [
                  "Customer Journey Mapping",
                  "Prototyping",
                  "Service Blueprint",
                ],
              },
            ]}
            challenge="
            <span>Our challenge was to agree on a set of journeys that make up the current state customer </span><br/>
            <span>lifecycle, and understand the pain points within them so that we can determine where our </span><br/>
            <span>team had the greatest opportunity to start work. </span><br/><br/>

            <span>At AGL, iteration is a day to day practice. For an ever-changing energy industry where </span><br/>
            <span>regulatory frameworks dictate pricing and distribution, it needed  to adopt focusing on </span><br/>
            <span>customer centric service products where affordability as well as clarity takes priority. </span><br/>
            "
          />
        ),
      },
      // Parachute
      {
        path: "case-studies/Parachute",
        element: (
          <Template
            headingImg={ParachuteHeadImg}
            challengeImg={ParachuteChallengeImg}
            deliverableImg={ParachuteDeliverablesImg}
            title="Parachute"
            location="Melbourne Australia"
            // label={true}
            projectType={["UX RESEARCH & UI DESIGN"]}
            whoTheyAre="
            <span>Parachute is a carpooling app for busy working</span> <br/>
            <span>caretakers with children in school while</span> <br/> 
            <span>juggling career and family.</span>  
                  "
            services={[
              "User Research",
              "Interaction Design",
              "Design Thinking/Ideation",
              "Rapid Prototyping",
              "Usability Testing",
              "Stakeholder Management",
            ]}
            overview="
            <span>Parachuute was a startup that was </span><br/> 
            <span>experiencing underperformance and </span><br/> 
            <span>struggled with user engagement . We </span><br/> 
            <span>provided end to end User Experience and </span><br/> 
            <span>user interface design. The challenge was </span><br/> 
            <span>approached with an end to end experience </span><br/> 
            <span>design lense. </span><br/> 
            "
            deliverables={[
              {
                title: "UX/UI",
                subtitles: [
                  "Persona(s)",
                  "User Flow",
                  "Lo & Hi Fidelity Wireframes",
                  "Competitor Analysis",
                  "Heuristic Analysis",
                ],
              },
            ]}
            challenge="
            <span>The client had an existing app but they noticed was underperforming. The UI and design</span><br/> 
            <span>system needed improvements in order address this issue and build a user base while</span><br/> 
            <span>increasing subscriptions.</span>  
                  "
          />
        ),
      },
      // Miracle Palm
      {
        path: "case-studies/Miracle Palm",
        element: (
          <Template
            headingImg={MiraclePalmHeadImg}
            challengeImg={MiraclePalmChallengeImg}
            deliverableImg={MiraclePalmDeliverablesImg}
            title="Miracle Palm"
            location="Los Angeles – USA "
            // label={true}
            projectType={["Branding & Package Design"]}
            whoTheyAre="
            <span>Miracle Palm is a natural foods manufacturer,</span> <br/>
            <span>importer and distributor based in Valencia,</span> <br/>
            <span>California. They specialize in Organic wholesale </span> <br/>
            <span>coconut based products.</span>
                        "
            services={[
              "User Research",
              "Testing",
              "Usability Testing",
              "Competitor Analysis ",
            ]}
            overview="
            <span>Miracle Palm is a leading distributor of </span><br/> 
            <span>organic coconut products. We were tasked </span><br/> 
            <span>with redesigning one of their current </span><br/> 
            <span>products packaging. After conducting in </span><br/> 
            <span>depth market research, we created a design </span><br/> 
            <span>that was simple and clean but still bold, in </span><br/> 
            <span>order to be easily identifiable to consumers. </span><br/> 
            "
            deliverables={[
              {
                title: "",
                subtitles: [
                  "Competitior Research",
                  "Branding",
                  "Design System",
                  "Product Design",
                  "Delivery",
                  "Iterations",
                ],
              },
            ]}
            challenge="
            <span>With this design we had to find the perfect niche between eye-catching and memorable</span><br/> 
            <span>for consumers while also keeping the clean and sleek brand identity of Miracle Palm.</span><br/> 
            <span>Through iterations and close collaboration with the client we achieved the current design.</span> 
            "
          />
        ),
      },
      // LACROSSE
      {
        path: "case-studies/LACROSSE",
        element: (
          <Template
            headingImg={LacrosseHeadImg}
            challengeImg={LacrosseChallengeImg}
            deliverableImg={LacrosseDeliverablesImg}
            title="LACROSSE"
            location="Melbourne – Australia"
            projectType={[
              "UXUI Design",
              "UI Development",
              "System Integration",
            ]}
            whoTheyAre="
              <span>Lacrosse is a property management. association </span> <br/>
              <span>who owns multiple properties across </span> <br/>
              <span>Melbourne Australia. Their buildings are home </span> <br/>
              <span>to residential and retail. </span> <br/>
              "
            services={[
              "User Research",
              "UX & Interaction Design",
              "User Testing",
              "UI Design",
              "UI Development",
              "API Connectivity",
              "System Integration",
            ]}
            overview="
              <span>Lacrosse properties wanted to make digital </span><br/> 
              <span>signage in order to keep their residents up to </span><br/> 
              <span>date on the tram status and schedule. We </span><br/> 
              <span>created a system that gets real time updates </span><br/> 
              <span>and communicates that information in an </span><br/> 
              <span>easily digestible way, for quick </span><br/> 
              <span>understanding, and ease of use for the users. </span><br/> 
              "
            deliverables={[
              {
                title: "",
                subtitles: [
                  "Competitor Analysis",
                  "User Research",
                  "Wireframes",
                  "Information Architecture",
                  "Usability Testing",
                  "UI for Widget App",
                  "Prototype",
                  "Product Delivery",
                  "System Integration",
                ],
              },
            ]}
            challenge="
            <span>Improving the accuracy & experience of IA/Content strategy for lacrosse lobby screens</span><br/> 
            <span>Tramtracker widget will achieve the efficiency of residents decision making process, time</span><br/> 
            <span>management and reach their destination safely & on time.</span>"
          />
        ),
      },
      // solutions
      {
        path: "solutions",
        element: <Solutions />,
      },
      {
        path: "startup-catalyst",
        element: <Startup />,
      },
      {
        path: "aboutus",
        element: <Aboutus />,
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "contactus",
        element: <Contact />,
      },
      {
        path: "funding-form",
        element: <FundingForm />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(false);

  // Function to handle screen size changes
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1200);
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize); // Update value on resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <Container>
      <h2
        className="reveal homepage_clients_h2"
        style={{ paddingBottom: "48px", paddingTop: "96px", color: "white" }}
      >
        Case Studies
      </h2>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {/* Video 1 / Photo 1 */}
          <div
            style={{
              paddingTop: "20px",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/Bupa")}
          >
            {isMobileView ? (
              <img
                src={require("../../img/Home Page/bupa519.png")}
                alt="Bupa Case Study"
                style={{ borderRadius: "20px", width: "100%" }}
              />
            ) : (
              <video
                className="video_heading"
                src={require("../../img/Home Page/bupa519.webm")}
                autoPlay
                loop
                muted
                playsInline
                style={{ borderRadius: "20px" }}
              />
            )}
          </div>

          {/* Video 2 */}
          <div
            style={{
              paddingTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/ANZ")}
          >
            <video
              className="video_heading"
              src={require("../../img/Home Page/anz519.webm")}
              autoPlay
              loop
              muted
              playsInline
              style={{ borderRadius: "20px" }}
            />
          </div>

          {/* Video 3 */}
          <div
            style={{
              paddingTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/SURYA")}
          >
            <video
              className="video_heading"
              src={require("../../img/Home Page/loonum519.webm")}
              autoPlay
              loop
              muted
              playsInline
              style={{ borderRadius: "20px" }}
            />
          </div>

          {/* Video 4 */}
          <div
            style={{
              paddingTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/Phenix")}
          >
            <video
              className="video_heading"
              src={require("../../img/Home Page/phenix519.webm")}
              autoPlay
              loop
              muted
              playsInline
              style={{ borderRadius: "20px" }}
            />
          </div>

          {/* Video 5 / Photo 2 */}
          <div
            style={{
              paddingTop: "20px",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate("/case-studies/Yalumba")}
          >
            {isMobileView ? (
              <img
                src={require("../../img/Home Page/yulumba519.png")}
                alt="Yalumba Case Study"
                style={{ borderRadius: "20px", width: "100%" }}
              />
            ) : (
              <video
                className="video_heading"
                src={require("../../img/Home Page/yulumba519.webm")}
                autoPlay
                loop
                muted
                playsInline
                style={{ borderRadius: "20px" }}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Clients;

import React from "react";
import ProcessInfographic from "../../components/ProcessInfographic-white";
import ProcessInfographicMobile from "../../components/ProcessInfographic-mobile-white";

const Process = () => {
  return (
    <div>
      <section className="solutions_process">
        <h2 style={{ height: 66 }} className="reveal w1460">
          Process
        </h2>
        <h2 style={{ height: 66, fontSize: 32 }} className="reveal w414">
          Process
        </h2>
        <div className="about_process_processInfographic w1460">
          <ProcessInfographic />
        </div>
        <div className="about_process_processInfographic w414">
          <ProcessInfographicMobile />
        </div>
      </section>
    </div>
  );
};

export default Process;
